import React, { useEffect, useState } from 'react';
import '../../css/worker/Saaleditor.css';
import '../../components/LoadingSpinner';
import LoadingSpinner from '../../components/LoadingSpinner';
import { API_URL } from '../../config';
import MenuBarWorker from "../../components/MenuBarWorker";






const Saaleditor = () => {
    const [rows, setRows] = useState(15);
    const [cols, setCols] = useState(15);
    const [saalnummer, setSaalnummer] = useState();
    const [seatsmap, setSeatsmap] = useState(Array(rows).fill().map(() => Array(cols).fill('classic')));
    const [selectedSeat, setSelectedSeat] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSeatClick = (row, col) => {
        setSelectedSeat({ row, col });
    };

    const handleSeatTypeChange = (row, col, newSeatType) => {
        const newSeats = seatsmap.map((r, rIndex) =>
            r.map((seat, cIndex) => {
                if (rIndex === row && cIndex === col) {
                    return newSeatType;
                }
                return seat;
            })
        );
        setSeatsmap(newSeats);
        setSelectedSeat(null);
    };

    const handleSave = async (event) => {
        event.preventDefault();
        alert('Saal gespeichert');
        const data = {
            saalnummer: saalnummer,
            seats: seatsmap,
        };

        try {
            const response = await fetch(API_URL + "/SaalSpeichern", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
        } catch (error) {
            console.error('Fehler:', error.message);
        }
    };
    const handleLoadSaal = async (event) => {
        const saalnummer = event.target.value;
        setSaalnummer(saalnummer);
        if (saalnummer === '') {
            return;
        }
        setLoading(true);
        try {
            const response = await fetch(API_URL + `/SaalLaden/${saalnummer}`);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            tansformdataforseatmap(data);
            console.log(data);

        } catch (error) {
            console.error('Fehler:', error.message);
        }
        finally {
            setLoading(false);
        }
    };

    const tansformdataforseatmap = async (data) => {
        const maxRow = Math.max(...data.map(seat => seat.reihe.charCodeAt(0))) - 'A'.charCodeAt(0) + 1;
        setRows(maxRow);
        const maxCol = Math.max(...data.map(seat => seat.nummer)) + 1;
        setCols(maxCol);
        const updatedSeatsmap = Array(maxRow).fill().map(() => Array(maxCol).fill('classic'));
        data.forEach(seat => {
            const rowIndex = seat.reihe.charCodeAt(0) - 'A'.charCodeAt(0); // Reihe in Zahl umwandeln
            const colIndex = seat.nummer;
            updatedSeatsmap[rowIndex][colIndex] = seat.kategorie; // Sitzkategorie setzen
        });
        setSeatsmap(updatedSeatsmap);
    }



    return (
        <>
            <MenuBarWorker />
            <div className='worker-container'>
                <div className="saal-editor-container">
                    {loading && <LoadingSpinner />}
                    {!loading && (
                        <div className="input-container">
                            <label>
                                Saalnummer:
                                <input
                                    type="number"
                                    value={saalnummer}
                                    onChange={handleLoadSaal}
                                />
                            </label>
                            <label>
                                Rows:
                                <input
                                    type="number"
                                    value={rows}
                                    onChange={(e) => {
                                        const newrows = parseInt(e.target.value);
                                        if (newrows > 0) {
                                            setSeatsmap(Array(newrows).fill().map(() => Array(cols).fill('classic')));
                                            setRows(newrows);
                                        }
                                    }}
                                />
                            </label>
                            <label>
                                Cols:
                                <input
                                    type="number"
                                    value={cols}
                                    onChange={(e) => {
                                        const newCols = parseInt(e.target.value);
                                        if (newCols > 0) {
                                            setSeatsmap(Array(rows).fill().map(() => Array(newCols).fill('classic')));
                                            setCols(newCols);
                                        }
                                    }}
                                />
                            </label>
                        </div>
                    )}
                    {!loading && (
                        <div className="saal-editor-frame">
                            {/* Leinwand-Box oben */}
                            <div className="canvas">
                                Leinwand
                            </div>

                            {/* Sitz-Grid darunter */}
                            <div className="saal-editor">
                                {seatsmap.map((row, rowIndex) => (
                                    <div key={rowIndex} className="row">
                                        {row.map((seat, colIndex) => (
                                            <div
                                                key={colIndex}
                                                className={`seat ${seat}`}
                                                onClick={() => handleSeatClick(rowIndex, colIndex)}
                                            >
                                                {seat === 'classic' ? 'C' :
                                                    seat === 'premium' ? 'P' :
                                                        seat === 'disabled' ? 'D' :
                                                            seat === 'loge' ? 'L' :
                                                                seat === 'gang' ? '' :
                                                                    ''}

                                                {selectedSeat && selectedSeat.row === rowIndex && selectedSeat.col === colIndex && (
                                                    <div className="popup">
                                                        <select
                                                            value={seat}
                                                            onChange={(e) => handleSeatTypeChange(rowIndex, colIndex, e.target.value)}
                                                        >
                                                            <option value="classic">Classic</option>
                                                            <option value="premium">Premium</option>
                                                            <option value="disabled">Disabled</option>
                                                            <option value="loge">Loge</option>
                                                            <option value="gang">Gang</option>
                                                            <option value="none">None</option>
                                                        </select>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                            <button
                                className="save-button"
                                onClick={handleSave}>
                                Speichern
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Saaleditor;