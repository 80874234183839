import React, { useState, useEffect } from 'react';
import MenuBarReporting from '../../components/MenuBarReporting';
import '../../css/reporting/Feedback.css';
import { API_URL } from '../../config';

function Feedback() {
  const [feedbacks, setFeedbacks] = useState([]);
  const [selectedRating, setSelectedRating] = useState(null); // State für den Filter nach Sternebewertung
  const [ratingCounts, setRatingCounts] = useState({ 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }); // Zähler für jede Sternebewertung
  const [totalFeedbackCount, setTotalFeedbackCount] = useState(0); // Zähler für alle Bewertungen

  // Daten beim Laden der Komponente abrufen und Bewertungen zählen
  useEffect(() => {
    fetch(API_URL + '/Feedback')
      .then(response => response.json())
      .then(data => {
        setFeedbacks(data);
        countRatings(data); // Zählerstände aktualisieren
        setTotalFeedbackCount(data.length); // Gesamtzahl der Bewertungen setzen
      })
      .catch(error => console.error("Fehler beim Laden der Daten:", error));
  }, []);

  // Funktion zum Zählen der Bewertungen pro Sternebewertung
  const countRatings = (feedbackData) => {
    const counts = { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
    feedbackData.forEach(feedback => {
      counts[feedback.rating]++;
    });
    setRatingCounts(counts);
  };

  // Filtert die Feedbacks nach Sternebewertung
  const filteredFeedbacks = selectedRating !== null
    ? feedbacks.filter(feedback => feedback.rating === selectedRating)
    : feedbacks; // Zeigt alle Feedbacks, wenn kein Filter ausgewählt ist

  return (
    <>
      <MenuBarReporting />
      <div className="worker-container">
        {/* Feedback Section */}
        <section className="feedback">
          <h1>Feedback</h1>
        </section>

        <section>
          {/* Dropdown zur Filterung nach Sternebewertung */}
          <div className="filter-container">
            <label htmlFor="rating-filter">Sternebewertung filtern:</label>
            <select
              id="rating-filter"
              value={selectedRating !== null ? selectedRating : ''}
              onChange={(e) => setSelectedRating(e.target.value !== '' ? parseInt(e.target.value) : null)}
            >
              <option value="">
                Alle Bewertungen ({totalFeedbackCount})
              </option>
              <option value={5}>5 Sterne ({ratingCounts[5]})</option>
              <option value={4}>4 Sterne ({ratingCounts[4]})</option>
              <option value={3}>3 Sterne ({ratingCounts[3]})</option>
              <option value={2}>2 Sterne ({ratingCounts[2]})</option>
              <option value={1}>1 Stern ({ratingCounts[1]})</option>
              <option value={0}>0 Sterne ({ratingCounts[0]})</option>
            </select>
          </div>
        </section>

        <section>
          {/* Feedback Boxen */}
          <div className="feedback-container">
            {filteredFeedbacks.map(feedback => (
              <div key={feedback.id} className="feedback-box">
                <p>{feedback.review}</p>

                {/* Sternebewertung anzeigen */}
                <div className="rating">
                  {[1, 2, 3, 4, 5].map(star => (
                    <span
                      key={star}
                      className={`star ${feedback.rating >= star ? 'selected' : ''}`}
                    >
                      ★
                    </span>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
}

export default Feedback;
