import React, { createContext, useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode'; // Prüfen, dass jwtDecode korrekt importiert wird

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null); // Null für anfänglichen Zustand
    const [userId, setUserId] = useState(null);
    const [accountType, setAccountType] = useState(null);
    const [email, setEmail] = useState(null);

    useEffect(() => {
        const token = sessionStorage.getItem('token');
        if (token) {
            initializeAuthState(token);
        } else {
            setIsAuthenticated(false); // Kein Token gefunden, daher nicht authentifiziert
        }
    }, []);

    const initializeAuthState = (token) => {
        try {
            const decodedToken = jwtDecode(token);
            const { userId, accountType, email, exp } = decodedToken;

            if (exp * 1000 > Date.now()) {
                setIsAuthenticated(true);
                setUserId(userId);
                setAccountType(accountType);
                setEmail(email);
            } else {
                logout();
            }
        } catch (error) {
            console.error('Ungültiger Token:', error);
            logout();
        }
    };

    const login = (token) => {
        sessionStorage.setItem('token', token);
        initializeAuthState(token);
    };

    const logout = () => {
        sessionStorage.removeItem('token');
        setIsAuthenticated(false);
        setUserId(null);
        setAccountType(null);
        setEmail(null);
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, userId, accountType, email, login, logout }}>
            {isAuthenticated !== null && children} {/* Nur laden, wenn Auth-Zustand initialisiert ist */}
        </AuthContext.Provider>
    );
};