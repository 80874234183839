import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../css/customer/Checkout.css';
import MenuBar from '../../components/MenuBar';
import Footer from '../../components/Footer';
import { AuthContext } from '../../components/AuthContext';
import { API_URL } from '../../config'; // Make sure to import your API_URL

export default function Checkout() {
    const location = useLocation();
    const navigate = useNavigate();
    const { isAuthenticated } = useContext(AuthContext);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [showOverlay, setShowOverlay] = useState(false);
    const { userId } = useContext(AuthContext); // Ensure AuthContext provides userId

    // New state variables for discount code
    const [discountCode, setDiscountCode] = useState('');
    const [discountApplied, setDiscountApplied] = useState(false);
    const [discountPercentage, setDiscountPercentage] = useState(0);
    const [discountError, setDiscountError] = useState('');
    const [codeId, setCodeId] = useState(null);
    const [generalCode, setGeneralCode] = useState(false);

    const {
        selectedSeats,
        seatCategoriesAndIds,
        seatDiscounts,
        totalPrice: originalTotalPrice, // Rename to originalTotalPrice
        movieTitle,
        time,
        room,
        date,
        id
    } = location.state || {};

    // Calculate total price after discount
    const totalPrice = discountApplied
        ? (originalTotalPrice * (1 - discountPercentage / 100)).toFixed(2)
        : originalTotalPrice;

    const getDiscountLabel = (seat, discount) => {
        switch (discount) {
            case 'child':
                return `Kind`;
            case 'senior':
                return `Senior`;
            case 'student':
                return `Student`;
            default:
                return `Normal`;
        }
    };

    const validateName = (name) => {
        const namePattern = /^[A-Za-zÄÖÜäöüß]+$/;
        return namePattern.test(name);
    };

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const handleEmailChange = (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);

        if (emailValue.toLowerCase() === 'edelbertschumm@dhbw.de') {
            setShowOverlay(true);
        }
    };

    if (!selectedSeats || !seatDiscounts || !originalTotalPrice) {
        return (
            <div className="checkout-page">
                <div className="container">
                    <MenuBar />
                </div>
                <div className="checkout-container">
                    <div className="error-message">
                        Es sind keine Ticketinformationen verfügbar. Bitte wählen Sie Ihre Sitze aus.
                    </div>
                    <button onClick={() => navigate(-1)} className="back-button">
                        Zurück zur Sitzplatzwahl
                    </button>
                </div>
                <Footer />
            </div>
        );
    }

    const handlePaymentSelection = (method) => {
        setPaymentMethod(method);
        setErrorMessage('');
    };

    const handleTermsChange = (event) => {
        setTermsAccepted(event.target.checked);
        setErrorMessage('');
    };

    const handleCheckout = (e) => {
        e.preventDefault();

        let newErrors = {};

        // If the user is not authenticated, validate personal info fields
        if (!isAuthenticated) {
            if (!validateName(firstName)) {
                newErrors.firstName = 'Vorname darf nur Buchstaben enthalten!';
            }

            if (!validateName(lastName)) {
                newErrors.lastName = 'Nachname darf nur Buchstaben enthalten!';
            }

            if (!validateEmail(email)) {
                newErrors.email = 'Bitte eine gültige E-Mail-Adresse eingeben!';
            }
        }

        if (!paymentMethod) {
            newErrors.paymentMethod = 'Bitte wählen Sie eine Zahlungsart aus.';
        }
        if (!termsAccepted) {
            newErrors.termsAccepted = 'Bitte akzeptieren Sie die AGB und Datenschutzbestimmungen.';
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setErrorMessage('');
        navigate('/purchase-complete', {
            state: {
                selectedSeats,
                seatCategoriesAndIds,
                seatDiscounts,
                totalPrice,
                movieTitle,
                time,
                room,
                date,
                paymentMethod,
                id,
                firstName: isAuthenticated ? '' : firstName,
                lastName: isAuthenticated ? '' : lastName,
                email: isAuthenticated ? '' : email,
                discountCode: discountApplied ? discountCode : null,
                codeId: discountApplied ? codeId : null,
                generalCode: discountApplied ? generalCode : null,
            },
        });
    };

    const handleApplyDiscount = () => {
        if (!discountCode) {
            setDiscountError('Bitte geben Sie einen Gutscheincode ein.');
            return;
        }

        // Validate the discount code with the backend
        fetch(`${API_URL}/validateDiscountCode`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ code: discountCode, userId }) // Pass userId along with the code
        })
            .then(response => response.json())
            .then(data => {
                if (data.valid) {
                    setDiscountPercentage(data.discountPercentage);
                    setDiscountApplied(true);
                    setDiscountError('');
                    setCodeId(data.code_id);
                    setGeneralCode(data.isGeneral)
                    alert(`Gutscheincode erfolgreich angewendet! Sie erhalten ${data.discountPercentage}% Rabatt.`);
                } else {
                    setDiscountError(data.message || 'Ungültiger oder abgelaufener Gutscheincode.');
                }
            })
            .catch(error => {
                console.error('Fehler beim Validieren des Gutscheincodes:', error);
                setDiscountError('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
            });
    };

    return (
        <>
            <MenuBar />
            <div className="main-container">
                <div className="checkout-container">
                    {errorMessage && (
                        <div className="error">
                            {errorMessage}
                        </div>
                    )}
                    <div className="ticket-overview">
                        <h2>Ticket-Überblick</h2>
                        <div className="ticket-details">
                            <p><strong>Film:</strong> {movieTitle}</p>
                            <p><strong>Datum:</strong> {new Date(date).toLocaleDateString('de-DE')}</p>
                            <p><strong>Uhrzeit:</strong> {time} Uhr</p>
                            <p><strong>Raum:</strong> {room}</p>
                            <p><strong>Sitze:</strong> {selectedSeats.join(', ')}</p>
                            <p><strong>Ermäßigungen:</strong></p>
                            <ul>
                                {selectedSeats.map((seat) => (
                                    <li key={seat}>
                                        {seat}: {getDiscountLabel(seat, seatDiscounts[seat])}
                                    </li>
                                ))}
                            </ul>
                            {discountApplied && (
                                <p>
                                    <strong>Rabatt:</strong> {discountPercentage}% Gutschein angewendet
                                </p>
                            )}
                            <p><strong>Gesamtpreis:</strong> {totalPrice}€</p>
                        </div>
                    </div>

                    {/* Discount Code Section */}
                    <div className="discount-code-section">
                        <h3>Gutscheincode einlösen</h3>
                        <div className="discount-code-input">
                            <input
                                type="text"
                                placeholder="Gutscheincode eingeben"
                                value={discountCode}
                                onChange={(e) => setDiscountCode(e.target.value)}
                            />
                            <button onClick={handleApplyDiscount}>Einlösen</button>
                        </div>
                        {discountError && <p className="error">{discountError}</p>}
                    </div>

                    {!isAuthenticated && (
                        <div className="personal-info">
                            <h3>Persönliche Daten</h3>
                            <form className="personal-form" onSubmit={handleCheckout}>
                                <div className="form-group">
                                    <label htmlFor="firstName">Vorname</label>
                                    <input
                                        type="text"
                                        id="firstName"
                                        name="firstName"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        required={!isAuthenticated}
                                    />
                                    {errors.firstName && <p className="error">{errors.firstName}</p>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="lastName">Nachname</label>
                                    <input
                                        type="text"
                                        id="lastName"
                                        name="lastName"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        required={!isAuthenticated}
                                    />
                                    {errors.lastName && <p className="error">{errors.lastName}</p>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">E-Mail</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={email}
                                        onChange={handleEmailChange}
                                        required={!isAuthenticated}
                                    />
                                    {errors.email && <p className="error">{errors.email}</p>}
                                </div>
                            </form>
                        </div>
                    )}

                    {showOverlay && (
                        <div className="overlay">
                            <img
                                src="/background.jpg"
                                alt="Du blind oder was??????"
                                className="full_screen"
                            />
                        </div>
                    )}

                    <div className="payment-methods">
                        <h3>Zahlungsart wählen</h3>
                        {errors.paymentMethod && <p className="error">{errors.paymentMethod}</p>}
                        <div className="payment-options">
                            <button
                                type="button"
                                className={`payment-button ${paymentMethod === 'Klarna' ? 'selected' : ''}`}
                                onClick={() => handlePaymentSelection('Klarna')}
                                aria-label="Zahlungsart Klarna auswählen"
                            >
                                Klarna
                            </button>
                            <button
                                type="button"
                                className={`payment-button ${paymentMethod === 'PayPal' ? 'selected' : ''}`}
                                onClick={() => handlePaymentSelection('PayPal')}
                                aria-label="Zahlungsart PayPal auswählen"
                            >
                                PayPal
                            </button>
                            <button
                                type="button"
                                className={`payment-button ${paymentMethod === 'Kreditkarte' ? 'selected' : ''}`}
                                onClick={() => handlePaymentSelection('Kreditkarte')}
                                aria-label="Zahlungsart Kreditkarte auswählen"
                            >
                                Kreditkarte
                            </button>
                            <button
                                type="button"
                                className={`payment-button ${paymentMethod === 'Gutschein' ? 'selected' : ''}`}
                                onClick={() => handlePaymentSelection('Gutschein')}
                                aria-label="Zahlungsart Gutschein auswählen"
                            >
                                Gutschein
                            </button>
                        </div>
                    </div>

                    <div className="terms-and-conditions">
                        <input
                            type="checkbox"
                            id="terms"
                            checked={termsAccepted}
                            onChange={handleTermsChange}
                        />
                        <label htmlFor="terms">
                            Ich akzeptiere die <a href="/agb">AGB</a> und die <a href="/datenschutz">Datenschutzbestimmungen</a>
                        </label>
                        {errors.termsAccepted && <p className="error">{errors.termsAccepted}</p>}
                    </div>

                    <div className="checkout-button-container">
                        <button
                            className="checkout-button"
                            type="submit"
                            onClick={handleCheckout}
                        >
                            Zahlung abschließen
                        </button>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}