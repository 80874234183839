import React, {useState, useEffect, useRef, useContext} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../css/customer/PurchaseComplete.css';
import MenuBar from '../../components/MenuBar';
import Footer from '../../components/Footer';
import { AuthContext } from '../../components/AuthContext';
import { API_URL } from '../../config';

export default function PurchaseComplete() {
    const location = useLocation();
    const navigate = useNavigate();
    const [buchungen, setBuchungen] = useState([]);
    const [buchungsdetails, setBuchungsdetails] = useState([]);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [isDetailDataFetched, setIsDetailDataFetched] = useState(false);
    const [gebuchtMessage, setGebuchtMessage] = useState("Vielen Dank für Ihren Kauf!");
    const [aufschlag, setAufschlag] = useState({
        logenAufschlag: 0,
        luxusLogenAufschlag: 0,
    });
    const { userId } = useContext(AuthContext);
    const kunde_id  = userId || 0;
    const [alreadyBookedSeats, setAlreadyBookedSeats] = useState(false); // Neuer Zustand für bereits gebuchte Plätze
    const {
        selectedSeats,
        seatCategoriesAndIds,
        seatDiscounts,
        totalPrice,
        movieTitle,
        time,
        room,
        date,
        paymentMethod,
        id,
        firstName,
        lastName,
        email,
        discountCode, // Rabattcode aus dem Zustand abrufen
        codeId, // code_id aus dem Zustand abrufen
        generalCode,
    } = location.state || {};
    let price = 10;
    const [rabatte, setRabatte] = useState({
        kinderRabatt: 0,
        seniorenRabatt: 0,
        studentenRabatt: 0,
    });
    const [bookingSaved, setBookingSaved] = useState(false);
    const [rating, setRating] = useState(0);
    const [review, setReview] = useState('');

    useEffect(() => {
        fetch(API_URL +'/Preise')
            .then((response) => response.json())
            .then((data) => {
                setRabatte({
                    kinderRabatt: data.kinderRabatt,
                    seniorenRabatt: data.seniorenRabatt,
                    studentenRabatt: data.studentenRabatt,
                });
                setAufschlag({
                    logenAufschlag: data.logenAufschlag,
                    luxusLogenAufschlag: data.luxusLogenAufschlag,
                });
                price = data.basisPreis;
            })
            .catch((error) => console.error('Fehler beim Abrufen der Rabatte:', error));
    }, []);

    useEffect(() => {
        fetch( API_URL + '/Buchungen')
            .then((response) => response.json())
            .then((data) => {
                setBuchungen(data);
                setIsDataFetched(true);
            })
            .catch((error) => {
                console.error('Fehler beim Abrufen der Buchungen:', error);
            });
    }, []);

    useEffect(() => {
        fetch(API_URL + '/Buchungsdetails')
            .then((response) => response.json())
            .then((data) => {
                setBuchungsdetails(data);
                setIsDetailDataFetched(true);
            })
            .catch((error) => {
                console.error('Fehler beim Abrufen der Buchungsdetails:', error);
            });
    }, []);

    useEffect(() => {
        if (
            selectedSeats &&
            seatDiscounts &&
            totalPrice &&
            isDataFetched &&
            isDetailDataFetched &&
            !hasSavedBooking.current
        ) {
            if (!alreadyBooked()) {
                saveBooking();
            } else {
                setAlreadyBookedSeats(true); // Setzt den Zustand, dass die Plätze bereits gebucht sind
            }
            hasSavedBooking.current = true;
        }
    }, [selectedSeats, seatDiscounts, totalPrice, isDataFetched, isDetailDataFetched]);

    const getDiscountLabel = (discount) => {
        switch (discount) {
            case 'child':
                return `Kind`;
            case 'senior':
                return `Senior`;
            case 'student':
                return `Student`;
            default:
                return `Normal`;
        }
    };

    const alreadyBooked = () => {
        let place = false;
        for (let j = 0; j < buchungen.length; j++) {
            if (buchungen[j].vorstellung_id.toString() === id.toString()) {
                selectedSeats.forEach((seat, index) => {
                    for (let i = 0; i < buchungsdetails.length; i++) {
                        if (
                            seatCategoriesAndIds[index].seatId === buchungsdetails[i].sitz_id &&
                            buchungen[j].buchung_id === buchungsdetails[i].buchung_id
                        ) {
                            place = true;
                        }
                    }
                });
            }
        }
        return place;
    };

    const getDiscountPrice = (sitzart, discount) => {
        let finalPrice = price;
        if (sitzart === 'loge') {
            finalPrice += (finalPrice * aufschlag.logenAufschlag) / 100;
        } else if (sitzart === 'premium') {
            finalPrice += (finalPrice * aufschlag.luxusLogenAufschlag) / 100;
        }

        switch (discount) {
            case 'child':
                return finalPrice - (finalPrice * rabatte.kinderRabatt) / 100;
            case 'senior':
                return finalPrice - (finalPrice * rabatte.seniorenRabatt) / 100;
            case 'student':
                return finalPrice - (finalPrice * rabatte.studentenRabatt) / 100;
            default:
                return finalPrice;
        }
    };

    const saveBooking = () => {
        if (!bookingSaved) {
            const bookingData = {
                kunde_id: kunde_id, // remains 0 if the user is not logged in
                vorstellung_id: id,
                buchungsdatum: new Date().toISOString().slice(0, 10),
                gesamtpreis: totalPrice || 0,
                zahlungsmethode: paymentMethod || 'unbekannt',
                sitze: selectedSeats,
                ermaessigungen: seatDiscounts,
                loyalitaetspunkte: totalPrice * 100 || 0,
                tempVorname: kunde_id === 0 ? firstName : null,
                tempNachname: kunde_id === 0 ? lastName : null,
                tempEmail: kunde_id === 0 ? email : null,
                istTemporaer: kunde_id === 0,
            };

            fetch(API_URL + '/Buchungen', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bookingData),
            })
                .then(async (response) => {
                    let data;
                    try {
                        data = await response.json();
                    } catch (err) {
                        throw new Error('Fehler beim Parsen der JSON-Antwort');
                    }

                    if (!response.ok) {
                        const errorMessage = data.error || 'Fehler beim Speichern der Buchung';
                        throw new Error(errorMessage);
                    }

                    return data;
                })
                .then((data) => {
                    if (data && data.buchung_id) {
                        console.log('Buchung erfolgreich gespeichert!');
                        setBookingSaved(true);
                        saveBookingDetails(data.buchung_id);

                        // Add loyalty points to the user if logged in
                        if (kunde_id !== 0) {
                            const loyaltyPoints = totalPrice * 100;
                            updateLoyaltyPoints(kunde_id, loyaltyPoints);
                        }
                        // Rabattcode löschen, wenn einer verwendet wurde und dieser User spezifisch ist
                        if (discountCode && codeId && !generalCode) {
                            deleteUserDiscountCode(kunde_id, codeId);
                        }
                    } else {
                        console.error('Fehler: Buchungs-ID nicht erhalten');
                    }
                })
                .catch((error) => console.error('Fehler:', error.message));
        }
    };

// Function to update user's loyalty points
    const updateLoyaltyPoints = (userId, pointsToAdd) => {
        fetch(API_URL + '/updateLoyaltyPoints', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId, loyaltyPoints: pointsToAdd }),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Fehler beim Aktualisieren der Loyalitätspunkte');
                }
                console.log('Loyalitätspunkte erfolgreich aktualisiert');
            })
            .catch((error) => console.error('Fehler beim Aktualisieren der Loyalitätspunkte:', error.message));
    };

    // Funktion zum Löschen des verwendeten Rabattcodes
    const deleteUserDiscountCode = (userId, codeId) => {
        fetch(`${API_URL}/DeleteUserCode/${userId}/${codeId}`, {
            method: 'DELETE',
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Fehler beim Löschen des Rabattcodes');
                }
                console.log('Rabattcode erfolgreich gelöscht');
            })
            .catch((error) => console.error('Fehler beim Löschen des Rabattcodes:', error.message));
    };

    const saveBookingDetails = (newBookingId) => {
        selectedSeats.forEach((seat, index) => {
            const seatDetail = {
                buchung_id: newBookingId,
                sitz_id: seatCategoriesAndIds[index].seatId,
                rabatt: seatDiscounts[seat] || 'keine Ermäßigung',
                preis: getDiscountPrice(seatCategoriesAndIds[index].category, seatDiscounts[seat]),
            };

            fetch(API_URL + '/Buchungsdetails', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(seatDetail),
            })
                .then((response) => {
                    if (response.ok) {
                        console.log('Sitzdetails erfolgreich gespeichert!');
                    } else {
                        console.error('Fehler beim Speichern der Sitzdetails');
                    }
                })
                .catch((error) => console.error('Fehler:', error));
        });
    };

    const hasSavedBooking = useRef(false);

    const handleRatingChange = (stars) => {
        setRating(stars);
    };

    const handleReviewChange = (e) => {
        setReview(e.target.value);
    };

    const handleSubmitReview = () => {
        // Prepare the review data
        const reviewData = {
            rating: rating,
            review: review
        };

        // Send the review data to the server
        fetch(`${API_URL}/addReview`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(reviewData)
        })
            .then((response) => {
                if (response.ok) {
                    alert(`Vielen Dank für Ihre Bewertung: ${rating} Sterne und Kommentar: "${review}"`);
                    setRating(0); // Reset the rating
                    setReview(''); // Reset the review
                } else {
                    throw new Error('Fehler beim Speichern der Bewertung');
                }
            })
            .catch((error) => {
                console.error('Fehler beim Speichern der Bewertung:', error);
            });
    };

    const handleBackToSeatSelection = () => {
        navigate(`/seats/${id}`);
    };

    if (!selectedSeats) {
        return (
            <div className="purchase-complete-page">
                <div className="container">
                    <MenuBar />
                </div>
                <div className="purchase-complete-container">
                    <div className="error">
                        Es sind keine Transaktionsdetails verfügbar.
                    </div>
                    <button onClick={() => navigate('/')} className="back-button">
                        Zur Startseite
                    </button>
                </div>
                <Footer />
            </div>
        );
    }

    if (alreadyBookedSeats) {
        // Anzeige der Meldung, wenn die Plätze bereits gebucht sind
        return (
            <>
                <MenuBar />
                <div className="main-container">
                    <div className="purchase-complete-container">
                        <div className="error-message">
                            <h2>Von ihnen ausgewählte Plätze sind bereits gebucht.</h2>
                            <p>Bitte wählen Sie andere Plätze aus.</p>
                            <button onClick={handleBackToSeatSelection} className="back-button">
                                Zur Sitzplatzauswahl
                            </button>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }

    return (
        <>
            <MenuBar />
            <div className="main-container">
                <div className="purchase-complete-container">
                    <div className="thank-you-message">
                        <h2>{gebuchtMessage}</h2>
                    </div>

                    <div className="transaction-overview">
                        <h2>Transaktionsdetails</h2>
                        <div className="transaction-details">
                            <p><strong>Film:</strong> {movieTitle}</p>
                            <p><strong>Datum:</strong> {new Date(date).toLocaleDateString('de-DE')}</p>
                            <p><strong>Uhrzeit:</strong> {time} Uhr</p>
                            <p><strong>Raum:</strong> {room}</p>
                            <p><strong>Zahlungsmethode:</strong> {paymentMethod}</p>
                            <p><strong>Sitze:</strong> {selectedSeats.join(', ')}</p>
                            <p><strong>Ermäßigungen:</strong></p>
                            <ul>
                                {selectedSeats.map((seat) => (
                                    <li key={seat}>
                                        {seat}: {getDiscountLabel(seatDiscounts[seat])}
                                    </li>
                                ))}
                            </ul>
                            <p><strong>Gesamtpreis:</strong> {totalPrice}€</p>
                        </div>
                    </div>

                    <div className="review-section">
                        <h3>Bewertung abgeben</h3>
                        <label htmlFor="review">Kommentar:</label>
                        <textarea
                            id="review"
                            value={review}
                            onChange={handleReviewChange}
                            placeholder="Schreiben Sie Ihr Feedback hier..."
                        />
                        <div className="rating">
                            <p>Sternebewertung:</p>
                            {[1, 2, 3, 4, 5].map((star) => (
                                <button
                                    key={star}
                                    className={`star-button ${rating >= star ? 'selected' : ''}`}
                                    onClick={() => handleRatingChange(star)}
                                >
                                    ★
                                </button>
                            ))}
                        </div>
                        <button onClick={handleSubmitReview} className="submit-review">
                            Bewertung abgeben
                        </button>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}