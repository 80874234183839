import React from 'react';
import { Link } from 'react-router-dom';
import '../../css/worker/Ubersicht.css';
import MenuBar from "../../components/MenuBar";
import MenuBarWorker from '../../components/MenuBarWorker';

function UbersichtWorker() {
    return (
        <div>
            <MenuBarWorker />
            <div className="worker-container">
                <h1>Verwaltung</h1>

                <nav>
                    <ul className="navigation-menu">
                        <li>
                            <Link to="/Filme">Filmverwaltung</Link>
                        </li>
                        <li>
                            <Link to="/Preisgestaltung">Preisgestaltung</Link>
                        </li>
                        <li>
                            <Link to="/Saaleditor">Saaleditor</Link>
                        </li>
                        <li>
                            <Link to="/VeranstaltungenErstellen">Veranstaltung Erstellen</Link>
                        </li>
                        <li>
                            <Link to="/VeranstaltungenLoeschen">Veranstaltung Löschen</Link>
                        </li>
                        <li>
                            <Link to="/EventErstellen">Event Erstellen</Link>
                        </li>
                        <li>
                            <Link to="/buchungen">Buchungen</Link>
                        </li>
                        <li>
                            <Link to="/Oeffnungszeiten">Oeffnungszeiten</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}

export default UbersichtWorker;