import React from 'react';
import { Link } from 'react-router-dom';
import '../../css/worker/Ubersicht.css';
import Footer from '../../components/Footer';
import MenuBarOverview from "../../components/MenuBarOverview"; 

function UbersichtWorker() {
    return (
        <>
            <MenuBarOverview /> 
            <div className="worker-container">
                <h1>Übersicht</h1>
                <nav>
                    <ul className="navigation-menu">
                        <li>
                            <Link to="/Verwaltung">Filmverwaltung</Link>
                        </li>
                        <li>
                            <Link to="/Kundenverwaltung">Kundenverwaltung</Link>
                        </li>
                        <li>
                            <Link to="/ReportingHome">Reporting</Link>
                        </li>

                    </ul>
                </nav>
            </div>

        </>
    );
}

export default UbersichtWorker;