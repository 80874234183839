import React, { useState, useEffect } from 'react';
import MenuBarWorker from "../../components/MenuBarWorker";
import '../../css/worker/Preisgestaltung.css';
import { API_URL } from '../../config';

const OeffnungszeitenBearbeiten = () => {
    const [wochentage, setWochentage] = useState([
        'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag',
        'Freitag', 'Samstag', 'Sonntag'
    ]);
    const [availableWochentage, setAvailableWochentage] = useState([]);
    const [selectedWochentag, setSelectedWochentag] = useState('');
    const [oeffnungszeit, setOeffnungszeit] = useState('');
    const [schlusszeit, setSchlusszeit] = useState('');
    const [oeffnungszeitenList, setOeffnungszeitenList] = useState([]);

    useEffect(() => {
        // Fetch existing opening hours
        fetch(API_URL + '/Oeffnungzeiten')
            .then(response => response.json())
            .then(data => {
                setOeffnungszeitenList(data);

                // Filter out the weekdays that already have opening hours set
                const existingWochentage = data.map(item => item.wochentag);
                const filteredWochentage = wochentage.filter(
                    tag => !existingWochentage.includes(tag)
                );
                setAvailableWochentage(filteredWochentage);

                // If all weekdays are set, disable the form
                if (filteredWochentage.length === 0) {
                    setSelectedWochentag('');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [wochentage]);

    const handleSave = () => {
        if (!selectedWochentag || !oeffnungszeit || !schlusszeit) {
            alert('Bitte füllen Sie alle Felder aus.');
            return;
        }

        const data = {
            wochentag: selectedWochentag,
            oeffnungszeit,
            schlusszeit
        };

        fetch(API_URL + '/Oeffnungzeiten', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    alert('Öffnungszeit gespeichert.');
                    // Refresh the component to update available weekdays
                    //window.location.reload();
                } else {
                    alert('Fehler beim Speichern der Öffnungszeit.');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const handleDelete = (wochentag) => {
        fetch(API_URL + `/Oeffnungzeiten/${wochentag}`, {
            method: 'DELETE'
        })
            .then(response => {
                if (response.ok) {
                    alert(`Öffnungszeit für ${wochentag} gelöscht.`);
                    // Refresh the component to update available weekdays
                    window.location.reload();
                } else {
                    alert('Fehler beim Löschen der Öffnungszeit.');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    return (
        <>
            <MenuBarWorker />

            <div className='worker-container'>
                <h1>Öffnungszeiten Verwaltung</h1>

                <div className="Oeffnungszeiten">
                    {availableWochentage.length > 0 ? (
                        <>
                            <div>
                                <label>Wochentag: </label>
                                <select
                                    value={selectedWochentag}
                                    onChange={(e) => setSelectedWochentag(e.target.value)}
                                >
                                    <option value="">-- Wählen Sie einen Wochentag --</option>
                                    {availableWochentage.map((tag, index) => (
                                        <option key={index} value={tag}>{tag}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label>Öffnungszeit: </label>
                                <input
                                    type="time"
                                    value={oeffnungszeit}
                                    onChange={(e) => setOeffnungszeit(e.target.value)}
                                />
                            </div>
                            <div>
                                <label>Schlusszeit: </label>
                                <input
                                    type="time"
                                    value={schlusszeit}
                                    onChange={(e) => setSchlusszeit(e.target.value)}
                                />
                            </div>
                            <div>
                                <button onClick={handleSave}>Speichern</button>
                            </div>
                        </>
                    ) : (
                        <p>Alle Wochentage haben bereits Öffnungszeiten.</p>
                    )}

                    <h2>Bestehende Öffnungszeiten</h2>
                    <table>
                        <thead>
                        <tr>
                            <th>Wochentag</th>
                            <th>Öffnungszeit</th>
                            <th>Schlusszeit</th>
                            <th>Aktionen</th>
                        </tr>
                        </thead>
                        <tbody>
                        {oeffnungszeitenList.map((item, index) => (
                            <tr key={index}>
                                <td>{item.wochentag}</td>
                                <td>{item.oeffnungszeit}</td>
                                <td>{item.schlusszeit}</td>
                                <td>
                                    <button onClick={() => handleDelete(item.wochentag)}>Löschen</button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default OeffnungszeitenBearbeiten;