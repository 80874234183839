import React from 'react';
import MenuBar from '../../components/MenuBar';
import Footer from '../../components/Footer';
import '../../css/Footer/Impressum.css'

export default function Impressum() {
    return (
        <>
            <MenuBar />
            <div className="main-container">
                <h1>Impressum</h1>
                <div className="impressum-container">
                    <Section title="Anbieter dieses Angebots">
                        MovieHub Entertainment GmbH
                        <br />
                        Beispielstraße 10
                        <br />
                        12345 Beispielstadt
                        <br />
                        Umsatzsteueridentifikationsnummer: DE123456789
                        <br />
                        <br />
                        Amtsgericht Beispielstadt, HRB 98765, Sitz der Gesellschaft: Beispielstadt
                        <br />
                        Geschäftsführer: Max Mustermann, Maria Beispiel
                    </Section>

                    <Section title="Verantwortlich i.S.d. § 18 Abs. 2 MStV">
                        Max Mustermann, Beispielstraße 10, 12345 Beispielstadt
                    </Section>

                    <Section title="Kontaktaufnahme">
                        Um anderweitig Kontakt zu uns aufzunehmen, nutze bitte unser Kontaktformular auf der MovieHub-Website. Über das Formular kannst Du jeden Bereich von MovieHub direkt kontaktieren. Bei allgemeinen Fragen und Hinweisen findest Du dort den richtigen Ansprechpartner.
                        <br />
                        Alternativ erreichst Du uns telefonisch unter: +49 (0) 123 / 456 789 0 (Es fallen die Kosten für eine Verbindung ins deutsche Festnetz an; Kosten aus dem Mobilfunknetz können abweichen), oder per E-Mail unter info@moviehub.com.
                    </Section>

                    <Section title="Jugendschutzbeauftragter">
                        FSK Freiwillige Selbstkontrolle der Filmwirtschaft GmbH
                        <br />
                        Murnaustraße 6, 65189 Wiesbaden
                        <br />
                        Kontakt | Homepage
                    </Section>

                    <Section title="Haftung">
                        MovieHub übernimmt keine Haftung oder Garantie für die Aktualität, Richtigkeit und Vollständigkeit der bereitgestellten Informationen. Dies gilt auch für alle anderen Webseiten, auf die mittels Hyperlink verwiesen wird. MovieHub ist für den Inhalt der Webseiten, die über eine solche Verbindung erreicht werden, nicht verantwortlich.
                        <br />
                        Wir behalten uns das Recht vor, Änderungen oder Ergänzungen der bereitgestellten Informationen jederzeit vorzunehmen. Alle Inhalte auf dieser Website sind urheberrechtlich geschützt und dürfen ohne das Einverständnis des jeweiligen Rechteinhabers nicht verwendet werden.
                    </Section>

                    <Section title="Information zur Online-Streitbeilegung">
                        Die Europäische Kommission stellt unter http://ec.europa.eu/consumers/odr/ eine Plattform zur außergerichtlichen Online-Streitbeilegung bereit. MovieHub ist grundsätzlich nicht bereit und verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle nach dem Verbraucherstreitbeilegungsgesetz (VSGB) teilzunehmen.
                    </Section>
                </div>
            </div>
            <Footer />
        </>
    );
}
const Section = ({ title, children }) => (
    <div className="agb-section">
        <h2>{title}</h2>
        <p>{children}</p>
    </div>
);
