import React from 'react';
import { Link } from 'react-router-dom';


export default function MenuBarOverview() {
    return (
        <nav className="worker-menu-bar">
            
            <ul>
                <li><Link to="/">Home</Link></li>  

                
            </ul>
        </nav>
    );
}
