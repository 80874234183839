import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import AccountMenu from '../pages/account/accountMenu';

export default function MenuBar() {
    const location = useLocation(); // Aktueller Pfad wird ermittelt

    return (
        <nav className="main-menu-bar">
            <div className='logo'>
            <Link to="/Ubersicht">MovieHub</Link>
            </div>
            <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/Angebote">Angebote</Link></li>
                {/* <li><Link to="/Events">Events</Link></li> */}
                <li><AccountMenu from={location.pathname} /></li> {/* Aktueller Pfad wird übergeben */}
            </ul>
        </nav>
    );
}