import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import MenuBarReporting from '../../components/MenuBarReporting';
import Footer from '../../components/Footer';
import '../../css/account/formular.css';
import { API_URL } from '../../config';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function UserDatenMitAltersverteilung() {
  // Zustand für die Altersgruppendaten
  const [ageGroupData, setAgeGroupData] = useState({
    labels: ['18-24', '25-34', '35-44', '45-54', '55-64', '65+'],
    datasets: [
      {
        label: 'Anzahl der Personen',
        data: [0, 0, 0, 0, 0, 0], // Initialisieren mit 0
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  });

  // Zustand für die Geschlechterdaten
  const [genderData, setGenderData] = useState({
    labels: ['Männlich', 'Weiblich', 'Divers'],
    datasets: [
      {
        label: 'Anzahl der Personen',
        data: [0, 0, 0], // Initialisieren mit 0
        backgroundColor: [
          'rgba(54, 162, 235, 0.6)', // Blau für Männlich
          'rgba(255, 99, 132, 0.6)', // Rot für Weiblich
          'rgba(153, 102, 255, 0.6)', // Lila für Divers
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(153, 102, 255, 1)',
        ],
        borderWidth: 1,
      },
    ],
  });

  // Optionen für die Altersgruppen-Diagramm
  const ageGroupOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Anzahl der Personen pro Altersgruppe',
      },
    },
  };

  // Optionen für das Geschlechter-Diagramm
  const genderOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Anzahl der Personen nach Geschlecht',
      },
    },
  };

  // useEffect zum Abrufen der Alters- und Geschlechterdaten
  useEffect(() => {
    // Abrufen der Geschlechterdaten
    fetch(API_URL + '/usergender')
      .then(response => response.json())
      .then(data => {
        const maleCount = data.male || 0;
        const femaleCount = data.female || 0;
        const otherCount = data.other || 0;

        setGenderData({
          labels: ['Männlich', 'Weiblich', 'Divers'],
          datasets: [
            {
              label: 'Anzahl der Personen',
              data: [maleCount, femaleCount, otherCount],
              backgroundColor: [
                'rgba(54, 162, 235, 0.6)', // Blau für Männlich
                'rgba(255, 99, 132, 0.6)', // Rot für Weiblich
                'rgba(153, 102, 255, 0.6)', // Lila für Divers
              ],
              borderColor: [
                'rgba(54, 162, 235, 1)',
                'rgba(255, 99, 132, 1)',
                'rgba(153, 102, 255, 1)',
              ],
              borderWidth: 1,
            },
          ],
        });
      })
      .catch(error => console.error('Fehler beim Abrufen der Geschlechterdaten:', error));

    // Abrufen der Altersgruppendaten
    fetch(API_URL + '/userage')
      .then(response => response.json())
      .then(data => {

        // ['18-24', '25-34', '35-44', '45-54', '55-64', '65+']
        const ageCount18_24 = data.eighteenToTwentyfour   || 0;
        const ageCount25_34 = data.twentyfiveToThirtyfour || 0;
        const ageCount35_44 = data.thirtyfiveToFourtyfour || 0;
        const ageCount45_54 = data.fourtyfiveToFiftyfour  || 0;
        const ageCount55_64 = data.fiftyfiveToSixtyfour   || 0;
        const ageCount65    = data.sixtyfivePlus          || 0;

        setAgeGroupData({
          labels: ['18-24', '25-34', '35-44', '45-54', '55-64', '65+'],
          datasets: [
            {
              label: 'Anzahl der Personen',
              data: [ageCount18_24, ageCount25_34, ageCount35_44, ageCount45_54, ageCount55_64, ageCount65],
              backgroundColor: 'rgba(75, 192, 192, 0.6)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
            },
          ],
        });
      })
      .catch(error => console.error('Fehler beim Abrufen der Altersgruppendaten:', error));
  }, []); // Leeres Array als Abhängigkeit, damit es nur einmal beim Laden ausgeführt wird

  return (
    <>
       <MenuBarReporting />
      <div className="worker-container">
        <h1>Nutzerdaten mit Altersverteilung</h1>
        <div className="kundendaten">
          {/* Balkendiagramm für Altersgruppen */}
          <Bar data={ageGroupData} options={ageGroupOptions} />
          </div>
          <div>
            <p>   </p>
          {/* Balkendiagramm für Geschlecht */}
          <Bar data={genderData} options={genderOptions} />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default UserDatenMitAltersverteilung;
