import React, { useEffect, useState } from 'react';
import MenuBarWorker from '../../components/MenuBarWorker';
import '../../css/worker/DeleteDiscountCode.css';
import { API_URL } from '../../config';

const DeleteDiscountCodes = () => {
    const [discountCodes, setDiscountCodes] = useState([]);
    const [selectedDiscountCodes, setSelectedDiscountCodes] = useState([]);
    const [searchTerm, setSearchTerm] = useState(''); // Zustand für den Suchbegriff

    useEffect(() => {
        // Rabattcodes vom Javalin-Backend abrufen
        fetch(API_URL + '/allCodes')
            .then(response => response.json())
            .then(data => {
                setDiscountCodes(data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const handleSelect = (id) => {
        setSelectedDiscountCodes(prevSelected =>
            prevSelected.includes(id)
                ? prevSelected.filter(item => item !== id)
                : [...prevSelected, id]
        );
    };

    const selectAll = () => {
        if (selectedDiscountCodes.length === discountCodes.length) {
            setSelectedDiscountCodes([]);
        } else {
            setSelectedDiscountCodes(discountCodes.map(code => code.code_id));
        }
    };

    const handleDelete = () => {
        const deleteRequests = selectedDiscountCodes.map(id =>
            fetch(API_URL + `/Codes/${id}`, {
                method: 'DELETE'
            })
        );

        Promise.all(deleteRequests)
            .then(() => {
                setDiscountCodes(prevDiscountCodes =>
                    prevDiscountCodes.filter(code =>
                        !selectedDiscountCodes.includes(code.code_id)
                    )
                );
                setSelectedDiscountCodes([]);
            })
            .catch(error => {
                console.error('Error deleting data:', error);
            });
    };

    // Filtere Rabattcodes basierend auf der Suchanfrage (ID)
    const filteredDiscountCodes = discountCodes.filter(code =>
        code.code_id.toString().includes(searchTerm) // Suche nach ID
    );

    return (
        <>
            <MenuBarWorker />
            <div className='worker-container'>
                <h1>Rabattcodes Loeschen</h1>

                {/* Suchleiste */}
                <div className='search-container'>
                    <input
                        type="text"
                        placeholder="Suche nach ID..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>

                <div className='Tasten'>
                    <button onClick={handleDelete}>Löschen</button>
                    <button onClick={selectAll}>Alle Auswaehlen</button>
                </div>

                <div className='tabellenContainer'>
                    <table>
                        <thead>
                            <tr>
                                <th>Select</th>
                                <th>ID</th>
                                <th>Code</th>
                                <th>Rabatt (%)</th>
                                <th>Ablaufdatum</th>
                                <th>Erforderliche Punkte</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredDiscountCodes.map((code) => (
                                <tr key={code.code_id}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={selectedDiscountCodes.includes(code.code_id)}
                                            onChange={() => handleSelect(code.code_id)}
                                        />
                                    </td>
                                    <td>{code.code_id}</td>
                                    <td>{code.code}</td>
                                    <td>{code.discountPercentage}</td>
                                    <td>{code.expirationDate}</td>
                                    <td>{code.pointsRequired}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default DeleteDiscountCodes;
