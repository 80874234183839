import React, { useState } from 'react';
import MenuBar from '../../components/MenuBar'; 
import Footer from '../../components/Footer';   
import '../../css/Footer/FAQ.css'; 

const FAQ = () => {
    const [selectedCategory, setSelectedCategory] = useState('');

    const faqData = {
        "Allgemeine Fragen": [
            { question: "Wie lange im Voraus kann ich Tickets kaufen?", answer: "Tickets können bis zu 30 Tage im Voraus gekauft werden." },
            { question: "Kann ich online reservierte Tickets stornieren?", answer: "Ja, das Stornieren von reservierte Tickets ist möglich." },
            { question: "Wie erhalte ich meine Tickets?", answer: "Tickets werden per E-Mail zugeschickt oder können vor Ort abgeholt werden." },
            { question: "Gibt es eine maximale Anzahl an Tickets, die ich kaufen kann?", answer: "Ja, pro Bestellung können maximal 10 Tickets gekauft werden." },
            { question: "Wie lange ist mein Ticket gültig?", answer: "Das Ticket ist nur für die angegebene Vorstellung gültig." },
            { question: "Kann ich Tickets für mehrere Filme gleichzeitig kaufen?", answer: "Ja, Sie können für verschiedene Filme gleichzeitig Tickets buchen." }
        ],
        "Barrierefreiheit": [
            { question: "Gibt es Rollstuhlplätze?", answer: "Ja, wir bieten spezielle Rollstuhlplätze in jedem Saal an." },
            { question: "Ist das Kino barrierefrei zugänglich?", answer: "Unser Kino verfügt über barrierefreie Eingänge." },
            { question: "Gibt es spezielle Tickets für Senioren?", answer: "Ja, wir bieten vergünstigte Tickets für Senioren an." },
            { question: "Wie kann ich Rollstuhlplätze buchen?", answer: "Rollstuhlplätze können direkt über unser Buchungssystem oder im Kino reserviert werden." },
            { question: "Gibt es ein barrierefreies WC?", answer: "Ja, in jedem Kinoeingang gibt es barrierefreie Toiletten." },
            { question: "Bietet das Kino auch Untertitel an?", answer: "Ja, bei bestimmten Vorführungen sind Untertitel verfügbar." }
        ],
        "Bezahlung mit Klarna": [
            { question: "Wie kann ich mit Klarna bezahlen?", answer: "Wählen Sie Klarna als Zahlungsmethode beim Checkout." },
            { question: "Kann ich auch eine Teilzahlung machen?", answer: "Ja, Klarna bietet flexible Zahlungsoptionen an." },
            { question: "Wie funktioniert die Zahlung auf Rechnung mit Klarna?", answer: "Sie erhalten die Rechnung per E-Mail und haben 14 Tage Zeit, die Zahlung zu leisten." },
            { question: "Fallen bei Klarna zusätzliche Gebühren an?", answer: "Nein, die Nutzung von Klarna ist gebührenfrei, es sei denn, Sie wählen eine Ratenzahlung." },
            { question: "Kann ich Klarna auch für die Reservierung von Tickets nutzen?", answer: "Ja, Klarna kann sowohl für Ticketkäufe als auch für Reservierungen verwendet werden." },
            { question: "Was passiert, wenn ich mit Klarna nicht rechtzeitig bezahle?", answer: "Es können Mahngebühren anfallen, wenn die Zahlung innerhalb der Frist nicht erfolgt." }
        ],
        "Kinoticket": [
            { question: "Wie erhalte ich mein Kinoticket?", answer: "Ihr Ticket wird Ihnen per E-Mail zugeschickt oder kann vor Ort abgeholt werden." },
            { question: "Kann ich mein Ticket umtauschen?", answer: "Leider ist ein Umtausch nicht möglich." },
            { question: "Wie viele Tickets kann ich auf einmal kaufen?", answer: "Maximal 10 Tickets können in einer Buchung erworben werden." },
            { question: "Kann ich Tickets für Freunde kaufen?", answer: "Ja, Sie können Tickets für andere Personen kaufen." },
            { question: "Wann ist der beste Zeitpunkt, um Tickets zu kaufen?", answer: "Es wird empfohlen, Tickets mindestens 3 Tage vor der Vorstellung zu kaufen." },
            { question: "Wie kann ich ein Ticket für eine Sondervorstellung kaufen?", answer: "Sondervorstellungen können über die normale Website oder über unsere App gebucht werden." }
        ],
        "Technik": [
            { question: "Welche Technik verwendet das Kino?", answer: "Wir nutzen die neueste Dolby-Atmos-Technologie für Sound und Bild." },
            { question: "Bietet das Kino 3D-Vorführungen?", answer: "Ja, wir bieten auch 3D-Filme an." },
            { question: "Wird im Kino 4K-Technologie verwendet?", answer: "Ja, alle unsere Vorführungen sind in hochauflösendem 4K-Format." },
            { question: "Gibt es auch IMAX-Vorführungen?", answer: "Derzeit bieten wir keine IMAX-Vorführungen an." },
            { question: "Wie funktioniert das Dolby Atmos System?", answer: "Dolby Atmos sorgt für ein dreidimensionales Klangbild, das aus allen Richtungen kommt." }
        ],
        "FSK und Jugendschutz": [
            { question: "Welche FSK hat der Film?", answer: "Die FSK-Freigabe können Sie auf unserer Website beim jeweiligen Film einsehen." },
            { question: "Wie wird der Jugendschutz kontrolliert?", answer: "Beim Eintritt werden Altersnachweise überprüft." },
            { question: "Ab welchem Alter kann man ins Kino?", answer: "Kinder können nach der jeweiligen FSK-Freigabe ins Kino, sofern sie begleitet werden." },
            { question: "Gibt es eine Altersfreigabe für 18-Jährige?", answer: "Ja, Filme mit FSK 18 dürfen nur von Personen ab 18 Jahren gesehen werden." },
            { question: "Wie werden die Altersfreigaben für Filme festgelegt?", answer: "Die Altersfreigabe wird durch die FSK (Freiwillige Selbstkontrolle der Filmwirtschaft) vergeben." },
            { question: "Darf ich meinen Ausweis online hochladen, um Tickets zu kaufen?", answer: "Nein, der Altersnachweis muss beim Eintritt ins Kino vorgezeigt werden." }
        ],
        // Weitere Kategorien ...
    };

    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
    };

    return (
        <>
            <MenuBar />
            <div className="main-container">
                <h1>Häufig gestellte Fragen zum Kinobesuch</h1>
                <div className="faq-container">                
                    <div className="faq-controls">
                        <select onChange={handleCategoryChange} value={selectedCategory}>
                            <option value="">Kategorie auswählen</option>
                            {Object.keys(faqData).map((category, index) => (
                                <option key={index} value={category}>{category}</option>
                            ))}
                        </select>
                        <p>Wir konnten deine Frage nicht beantworten?
                            <br /> Dann nutze bitte unser
                            <a href="/kontakt"> Kontaktformular.</a></p>
                    </div>

                    <div className="faq-content">
                        {selectedCategory && faqData[selectedCategory].map((faq, index) => (
                            <div key={index} className="faq-item">
                                <h3>{faq.question}</h3>
                                <p>{faq.answer}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default FAQ;
