import { Link } from 'react-router-dom';
import React, { useContext } from 'react';
import '../../css/account/accountMenu.css';
import { AuthContext } from '../../components/AuthContext';

const AccountMenu = ({ from }) => {
    const { isAuthenticated, logout } = useContext(AuthContext);
    console.log(from)
    return (
        <div className="account-menu">
            <span>Mein Konto</span>
            <div className="account-dropdown">
                {isAuthenticated ? (
                    <>
                        <Link to="/" onClick={logout}>Abmelden</Link>
                        <Link to="/account/manageAccount">Konto verwalten</Link>
                    </>
                ) : (
                    <>
                        <Link to="/login" state={{ from }}>Einloggen</Link>
                        <Link to="/account/register" state={{ from }}>Registrieren</Link>
                    </>
                )}
            </div>
        </div>
    );
};

export default AccountMenu;