import React from 'react';
import { Link } from 'react-router-dom';


export default function MenuBarReporting() {
    return (
        <nav className="worker-menu-bar">
            
            <ul>
                <li><Link to="/Ubersicht">Übersicht</Link></li>
                <li><Link to="/">Home</Link></li>     
                
            </ul>
        </nav>
    );
}
