import React, { useState, useEffect, useContext } from 'react';
import MenuBar from '../../components/MenuBar'; // Menüleiste importieren
import Footer from '../../components/Footer'; // Footer importieren
import '../../css/worker/BookingOverview.css';
import { API_URL } from '../../config';
import { AuthContext } from "../../components/AuthContext"; // Optionales CSS für die Tabelle

export default function Cancellation() {
    // State für die Buchungen
    const [buchungen, setBuchungen] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [buchungsdetails, setBuchungsdetails] = useState([]);
    const [veranstaltungen, setVeranstaltungen] = useState([]);
    const [seats, setSeats] = useState([]); // Sitzplätze aus der Datenbank
    const { userId } = useContext(AuthContext);
    const [isUserIdReady, setIsUserIdReady] = useState(false); // New state to track if userId is ready

    const kundeId = userId || 0;
    let i = 0;

    // Set isUserIdReady to true when userId changes and is not null
    useEffect(() => {
        if (userId) {
            setIsUserIdReady(true);
        }
    }, [userId]);

    // Lade alle Buchungen aus der Datenbank, only if userId is ready
    useEffect(() => {
        if (isUserIdReady) {
            fetch(API_URL + `/Buchungen/${kundeId}`)
                .then((response) => response.json())
                .then((data) => {
                    setBuchungen(data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Fehler beim Abrufen der Buchungen:', error);
                    setError('Fehler beim Abrufen der Buchungen');
                    setLoading(false);
                });
        }
    }, [isUserIdReady, kundeId]);

    useEffect(() => {
        if (isUserIdReady) {
            fetch(API_URL + '/Buchungsdetails') // API-Aufruf an den neuen Backend-Endpunkt
                .then((response) => response.json())
                .then((data) => {
                    setBuchungsdetails(data);
                })
                .catch((error) => {
                    console.error('Fehler beim Abrufen der Buchungsdetails:', error);
                });
        }
    }, [isUserIdReady]);

    useEffect(() => {
        if (isUserIdReady) {
            fetch(API_URL + '/Veranstaltungen') // API-Aufruf an den neuen Backend-Endpunkt
                .then((response) => response.json())
                .then((data) => {
                    setVeranstaltungen(data);
                })
                .catch((error) => {
                    console.error('Fehler beim Abrufen der Buchungsdetails:', error);
                });
        }
    }, [isUserIdReady]);

    useEffect(() => {
        if (isUserIdReady) {
            fetch(API_URL + `/SaalLaden`) // API-Endpunkt basierend auf der Saalnummer
                .then(response => response.json())
                .then(data => {
                    setSeats(data); // Setze die Sitzplatzdaten
                })
                .catch(error => console.error('Fehler beim Laden der Sitzplätze:', error));
        }
    }, [isUserIdReady]);

// Function to handle feedback submission
// Function to handle feedback submission
    const submitFeedback = async (reason) => {
        try {
            const response = await fetch(API_URL + `/addCancellationReason/${userId}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ reason })
            });
            if (!response.ok) {
                throw new Error('Fehler beim Senden des Feedbacks');
            }
        } catch (error) {
            console.error('Fehler beim Feedback senden:', error);
            throw error; // Re-throw the error to handle it in the main function
        }
    };

    const storniereBuchung = (buchungId) => {
        if (window.confirm('Möchten Sie diese Buchung wirklich stornieren?')) {
            // Prompt the user for feedback after confirmation
            const feedback = window.prompt('Bitte geben Sie einen Grund für die Stornierung an:');

            if (feedback) {
                setLoading(true); // Ladezustand aktivieren

                // Perform the DELETE operation for Buchungen and send feedback
                Promise.all([
                    fetch(API_URL + `/Buchungen/${buchungId}`, { method: 'DELETE' }),
                    submitFeedback(feedback)
                ])
                    .then(([buchungResponse]) => {
                        if (buchungResponse.ok) {
                            setBuchungen(buchungen.filter(buchung => buchung.buchung_id !== buchungId));
                            alert('Buchung und Feedback erfolgreich verarbeitet.');
                        } else {
                            throw new Error('Fehler beim Stornieren der Buchung');
                        }
                    })
                    .catch((error) => {
                        console.error('Fehler beim Stornieren:', error);
                        setError('Fehler beim Stornieren der Buchung oder beim Senden des Feedbacks');
                    })
                    .finally(() => {
                        setLoading(false); // Ladezustand beenden
                    });
            } else {
                alert('Bitte geben Sie einen Grund für die Stornierung an, um fortzufahren.');
            }
        }
    };

    if (loading) {
        return (
            <div className="booking-overview">
                <div className="container">
                    <MenuBar />
                </div>
                <div className="loading">
                    <p>Lädt Buchungen...</p>
                </div>
                <Footer />
            </div>
        );
    }

    if (error) {
        return (
            <div className="booking-overview">
                <div className="container">
                    <MenuBar />
                </div>
                <div className="error">
                    <p>{error}</p>
                </div>
                <Footer />
            </div>
        );
    }

    const anzeigen = (attribut, ID) => {
        switch (attribut) {
            case 'film':
                for (i = 0; i < veranstaltungen.length; i++) {
                    if (veranstaltungen[i].id === ID) {
                        return veranstaltungen[i].film;
                    }
                }
                return "Film nicht Vorhanden";
            case 'datum':
                for (i = 0; i < veranstaltungen.length; i++) {
                    if (veranstaltungen[i].id === ID) {
                        return veranstaltungen[i].datum;
                    }
                }
                return "Uhrzeit nicht Vorhanden";
            case 'uhrzeit':
                for (i = 0; i < veranstaltungen.length; i++) {
                    if (veranstaltungen[i].id === ID) {
                        return veranstaltungen[i].uhrzeit;
                    }
                }
                return "Uhrzeit nicht Vorhanden";
            case 'sitze':
                let bookedSeats = "";
                let j = 0;
                for (i = 0; i < buchungsdetails.length; i++) {
                    if (buchungsdetails[i].buchung_id === ID) {
                        for (j = 0; j < seats.length; j++) {
                            if (seats[j].sitz_id.toString() === buchungsdetails[i].sitz_id.toString()) {
                                bookedSeats = bookedSeats + seats[j].reihe + seats[j].nummer + " ";
                            }
                        }
                    }
                }
                return bookedSeats;
        }
    };
    return (
        <>
            <MenuBar />
            <div className="container">
                <div className="overview-container">
                    <h1>Buchungen Stornieren</h1>
                    <table className="booking-table">
                        <thead>
                        <tr>
                            <th>Film</th>
                            <th>Datum</th>
                            <th>Uhrzeit</th>
                            <th>Sitze</th>
                            <th>Gesamtpreis (€)</th>
                            <th>Aktionen</th>
                        </tr>
                        </thead>
                        <tbody>
                        {buchungen.length > 0 ? (
                            buchungen.map((buchung) => (
                                <tr key={buchung.buchung_id}>
                                    <td>{anzeigen("film", buchung.vorstellung_id)}</td>
                                    <td>{anzeigen("datum", buchung.vorstellung_id)}</td>
                                    <td>{anzeigen("uhrzeit", buchung.vorstellung_id)}</td>
                                    <td>{anzeigen("sitze", buchung.buchung_id)}</td>
                                    <td>{buchung.gesamtpreis.toFixed(2)}</td>
                                    <td>
                                        <button
                                            className="cancel-button"
                                            onClick={() => storniereBuchung(buchung.buchung_id)}
                                        >
                                            Stornieren
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="7">Keine Buchungen gefunden</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
            <Footer />
        </>
    );
}