import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../../css/account/formular.css';
import MenuBar from '../../components/MenuBar';
import Footer from '../../components/Footer';
import { API_URL } from '../../config';

const AdminAccountErstellen = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [gender, setGender] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [password, setPassword] = useState('');
    const [accountType, setAccountType] = useState('admin'); // Default ist Admin
    const [errors, setErrors] = useState({});

    // Funktion zur Namensvalidierung
    const validateName = (name) => /^[A-Za-z]+$/.test(name);

    // Funktion zur Geburtsdatumsvalidierung
    const validateBirthDate = (date) => {
        const today = new Date();
        const selectedDate = new Date(date);
        return selectedDate < today; // Geburtsdatum muss in der Vergangenheit liegen
    };

    // Eventhandler für die Registrierung
    const handleRegister = (e) => {
        e.preventDefault();

        // Validierungsfehler zurücksetzen
        let newErrors = {};

        // Vorname validieren
        if (!validateName(firstName)) {
            newErrors.firstName = 'Vorname darf nur Buchstaben enthalten!';
        }

        // Nachname validieren
        if (!validateName(lastName)) {
            newErrors.lastName = 'Nachname darf nur Buchstaben enthalten!';
        }

        // Geburtsdatum validieren
        if (!validateBirthDate(birthDate)) {
            newErrors.birthDate = 'Geburtsdatum muss in der Vergangenheit liegen!';
        }

        // Fehler anzeigen, wenn vorhanden
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        // Wenn keine Fehler vorhanden, die Registrierung fortsetzen
        setErrors({});
        registerData();
    };

    // Funktion, um Registrierungsdaten zu senden
    const registerData = () => {
        const adminData = {
            firstName,
            lastName,
            email,
            password,
            birthDate,
            gender,
            accountType, // accountType kann entweder "admin" oder "worker" sein
        };

        fetch(`${API_URL}/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(adminData),
        })
            .then((response) => {
                if (response.ok) {
                    console.log(`${accountType.charAt(0).toUpperCase() + accountType.slice(1)}-Account erfolgreich erstellt!`);
                } else {
                    console.error('Fehler bei der Registrierung', response);
                }
            })
            .catch((error) => console.error('Fehler:', error));
    };

    return (
        <>
            <MenuBar />
            <div className="main-container">
                <div className="formular-container">
                    <h1>Admin/Worker-Account erstellen</h1>
                    <form className="formular" onSubmit={handleRegister}>
                        <input
                            type="text"
                            placeholder="Vorname"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                        />
                        {errors.firstName && <p className="error">{errors.firstName}</p>}

                        <input
                            type="text"
                            placeholder="Nachname"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                        />
                        {errors.lastName && <p className="error">{errors.lastName}</p>}

                        <input
                            type="email"
                            placeholder="E-Mail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />

                        <select
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            required
                        >
                            <option value="">Geschlecht auswählen</option>
                            <option value="male">Männlich</option>
                            <option value="female">Weiblich</option>
                            <option value="other">Andere</option>
                        </select>

                        <input
                            type="date"
                            placeholder="Geburtsdatum"
                            value={birthDate}
                            onChange={(e) => setBirthDate(e.target.value)}
                            required
                        />
                        {errors.birthDate && <p className="error">{errors.birthDate}</p>}

                        <input
                            type="password"
                            placeholder="Passwort"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />

                        {/* Toggle für Account-Typ */}
                        <div className="account-type-toggle">
                            <label>
                                <input
                                    type="radio"
                                    name="accountType"
                                    value="admin"
                                    checked={accountType === 'admin'}
                                    onChange={(e) => setAccountType(e.target.value)}
                                />
                                Admin
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="accountType"
                                    value="worker"
                                    checked={accountType === 'worker'}
                                    onChange={(e) => setAccountType(e.target.value)}
                                />
                                Worker
                            </label>
                        </div>

                        <button type="submit">Account erstellen</button>
                        <p>Zurück zur <Link to="/login">Anmeldung</Link></p>
                    </form>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default AdminAccountErstellen;
