import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../css/account/formular.css';
import MenuBar from '../../components/MenuBar';
import Footer from '../../components/Footer';
import { API_URL } from '../../config';

const Register = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [gender, setGender] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [generalError, setGeneralError] = useState(''); // New state for general error messages
    const navigate = useNavigate(); // Initialize navigate

    // Function to validate name input
    const validateName = (name) => {
        const namePattern = /^[A-Za-z]+$/; // Only letters, no spaces
        return namePattern.test(name);
    };

    // Function to validate birth date
    const validateBirthDate = (date) => {
        const today = new Date();
        const selectedDate = new Date(date);
        return selectedDate < today; // Birthdate must be in the past
    };

    const handleRegister = (e) => {
        e.preventDefault();

        // Reset the error object
        let newErrors = {};
        setGeneralError(''); // Reset the general error message

        // Validate first name
        if (!validateName(firstName)) {
            newErrors.firstName = 'Vorname darf nur Buchstaben enthalten!';
        }

        // Validate last name
        if (!validateName(lastName)) {
            newErrors.lastName = 'Nachname darf nur Buchstaben enthalten!';
        }

        // Validate birth date
        if (!validateBirthDate(birthDate)) {
            newErrors.birthDate = 'Geburtsdatum muss in der Vergangenheit liegen!';
        }

        // If there are errors, set them and stop processing
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return; // Stop further processing
        }

        // If no errors, proceed to register the user
        setErrors({});
        registerData(); // Call registerData to send the registration data
    };

    const registerData = () => {
        const registerData = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
            birthDate: birthDate,
            gender: gender,
            accountType: "customer",
        };

        fetch(API_URL + '/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(registerData),
        })
            .then((response) => {
                if (response.ok) {
                    console.log('Registration erfolgreich gespeichert!');
                    navigate('/login');
                } else if (response.status === 409) {
                    // Status 409: Conflict, e.g., email already in use
                    setGeneralError('Diese E-Mail ist bereits registriert.');
                } else {
                    setGeneralError('Registrierung fehlgeschlagen. Bitte versuche es erneut.');
                }
            })
            .catch((error) => {
                console.error('Fehler:', error);
                setGeneralError('Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.');
            });
    };

    return (
        <>
            <MenuBar />
            <div className="main-container">
                <div className="formular-container">
                    <h1>Registriere dich jetzt!</h1>

                    <form className="formular" onSubmit={handleRegister}>
                        <input
                            type="text"
                            placeholder="Vorname"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                        />
                        {errors.firstName && <p className="error">{errors.firstName}</p>}

                        <input
                            type="text"
                            placeholder="Nachname"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                        />
                        {errors.lastName && <p className="error">{errors.lastName}</p>}

                        <input
                            type="email"
                            placeholder="E-Mail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />

                        <select
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            required
                        >
                            <option value="">Geschlecht auswählen</option>
                            <option value="male">Männlich</option>
                            <option value="female">Weiblich</option>
                            <option value="other">Andere</option>
                        </select>

                        <input
                            type="date"
                            placeholder="Geburtsdatum"
                            value={birthDate}
                            onChange={(e) => setBirthDate(e.target.value)}
                            required
                        />
                        {errors.birthDate && <p className="error">{errors.birthDate}</p>}

                        <input
                            type="password"
                            placeholder="Passwort"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />

                        <button type="submit">Registrieren</button>

                        {/* Display general error messages */}
                        {generalError && <p className="error">{generalError}</p>}

                        <p>Bereits einen Account? <Link to="/login">Einloggen</Link></p>
                    </form>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Register;