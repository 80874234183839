import React, { useState } from 'react';
import { useEffect } from 'react';
import MenuBarWorker from "../../components/MenuBarWorker";
import '../../css/worker/Preisgestaltung.css';
import { API_URL } from '../../config';

const Preisgestaltung = () => {
    const [basisPreis, setBasisPreis] = useState(0);
    const [logenAufschlag, setLogenAufschlag] = useState(0);
    const [luxusLogenAufschlag, setLuxusLogenAufschlag] = useState(0);
    const [kinderRabatt, setKinderRabatt] = useState(0);
    const [seniorenRabatt, setSeniorenRabatt] = useState(0);
    const [studentenRabatt, setStudentenRabatt] = useState(0);


    useEffect(() => {
        fetch(API_URL + '/Preise')
            .then(response => response.json())
            .then(data => {
                setBasisPreis(data.basisPreis);
                setLogenAufschlag(data.logenAufschlag);
                setLuxusLogenAufschlag(data.luxusLogenAufschlag);
                setKinderRabatt(data.kinderRabatt);
                setSeniorenRabatt(data.seniorenRabatt);
                setStudentenRabatt(data.studentenRabatt);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    const handleSave = () => {
        const data = {
            basisPreis,
            logenAufschlag,
            luxusLogenAufschlag,
            kinderRabatt,
            seniorenRabatt,
            studentenRabatt
        };

        fetch(API_URL + '/Preise', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    return (
        <>
            <MenuBarWorker />

            <div className='worker-container'>
                <h1>Preisgestaltung</h1>




                <div className="Preisgestaltung">
                <div>
                <button onClick={handleSave}>Speichern</button>
                </div>
                     <div>
                        <label>Basis Preis: </label>
                        <input
                            type="number"
                            value={basisPreis}
                            min="0"
                            onChange={(e) => setBasisPreis(Math.max(0, parseFloat(e.target.value)))}
                        />
                    </div>
                    <div>
                        <label>Logen Aufschlag  (%): </label>
                        <input
                            type="number"
                            value={logenAufschlag}
                            min="0"
                            max="100"
                            onChange={(e) => setLogenAufschlag(Math.min(100, Math.max(0, parseFloat(e.target.value))))}
                        />
                    </div>
                    <div>
                        <label>Luxus Logen Aufschlag  (%): </label>
                        <input
                            type="number"
                            value={luxusLogenAufschlag}
                            min="0"
                            max="100"
                            onChange={(e) => setLuxusLogenAufschlag(Math.min(100, Math.max(0, parseFloat(e.target.value))))}
                        />
                    </div>
                    <div>
                        <label>Kinder Rabatt (%): </label>
                        <input
                            type="number"
                            value={kinderRabatt}
                            min="0"
                            max="100"
                            onChange={(e) => setKinderRabatt(Math.min(100, Math.max(0, parseFloat(e.target.value))))}
                        />
                    </div>
                    <div>
                        <label>Senioren Rabatt (%): </label>
                        <input
                            type="number"
                            value={seniorenRabatt}
                            min="0"
                            max="100"
                            onChange={(e) => setSeniorenRabatt(Math.min(100, Math.max(0, parseFloat(e.target.value))))}
                        />
                    </div>
                    <div>
                        <label>Studenten Rabatt (%): </label>
                        <input
                            type="number"
                            value={studentenRabatt}
                            min="0"
                            max="100"
                            onChange={(e) => setStudentenRabatt(Math.min(100, Math.max(0, parseFloat(e.target.value))))}
                        />
                    </div>

                </div>
            </div>
        </>

    );
};

export default Preisgestaltung;