import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../css/worker/Filme.css';
import MenuBarWorker from '../../components/MenuBarWorker';
import Footer from '../../components/Footer';
import { API_URL } from '../../config';

function FetchMovies() {
    const [loading, setLoading] = useState(false);
    const [movies, setMovies] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredMovies, setFilteredMovies] = useState([]);
    const [movieId, setMovieId] = useState(''); // State for movie ID input
    const [youtubeUrl, setYoutubeUrl] = useState(''); // State for YouTube URL input
    const [fsk, setFsk] = useState(''); // State for FSK input

    useEffect(() => {
        fetchMovies();
    }, []);

    useEffect(() => {
        setFilteredMovies(
            movies.filter(movie =>
                movie.title.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, movies]);

    const deleteMovie = async (id) => {
        try {
            const response = await fetch(API_URL + `/filme/delete/${id}`, {
                method: 'DELETE',
            });
            if (response.status === 403) {
                alert('Film wird aktuell gezeigt und kann nicht gelöscht werden.');
                return;
            }

            if (!response.ok) {
                throw new Error('Failed to delete the movie.');
            }

            alert('Movie deleted successfully.');
            fetchMovies(); // Refresh the movie list after deletion
        } catch (error) {
            console.error('Error deleting the movie:', error);
        }
    };

    const addMovie = async () => {
        if (!movieId || !youtubeUrl || !fsk) { // Check for FSK
            alert('Please provide Movie ID, YouTube URL, and FSK.');
            return;
        }

        const fskNumber = parseInt(fsk, 10); // Convert FSK input to integer
        if (isNaN(fskNumber) || fskNumber < 0 || fskNumber > 18) { // Check for FSK validity
            alert('FSK must be a number between 0 and 18.');
            return;
        }
    
        setLoading(true);
        try {
            const response = await fetch(API_URL + '/filme/create', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: parseInt(movieId, 10), // Ensure the movieId is an integer
                    youtubeUrl: youtubeUrl,
                    fsk: fskNumber, // Add FSK to the request body
                }),
            });
    
            if (!response.ok) {
                throw new Error('Failed to add the movie.');
            }
    
            alert('Movie added successfully.');
            fetchMovies(); // Refresh the movie list
        } catch (error) {
            console.error('Error adding the movie:', error);
        } finally {
            setLoading(false);
            setMovieId('');
            setYoutubeUrl('');
            setFsk(''); // Reset FSK field
        }
    };

    const fetchMovies = async () => {
        setLoading(true);
        try {
            const response = await fetch(API_URL + '/filme/all', {
                method: 'GET',
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Netzwerk-Antwort war nicht ok: ${response.status} - ${errorText}`);
            }
            const data = await response.json();
            const moviesWithUsedStatus = data.map(movie => ({
                ...movie,
                used: Boolean(movie.used),
            }));

            setMovies(moviesWithUsedStatus);
            setFilteredMovies(moviesWithUsedStatus);
        } catch (error) {
            console.error('Es gab ein Problem mit der Fetch-Anfrage:', error);
        } finally {
            setLoading(false);
        }
    };


    const updateMovieUsedStatus = async (id, used) => {
        try {
            const response = await fetch(API_URL + `/filme/${id}/used?used=${used}`, {
                method: 'PUT',
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Netzwerk-Antwort war nicht ok: ${response.status} - ${errorText}`);
            }

            setMovies(prevMovies =>
                prevMovies.map(movie =>
                    movie.id === id ? { ...movie, used } : movie
                )
            );
        } catch (error) {
            console.error('Fehler beim Aktualisieren des Used-Status:', error);
        }
    };

    const handleMovieClick = (id) => {
        const movie = movies.find(m => m.id === id);
        const newUsedStatus = !movie.used; // Toggle den Used-Status
        updateMovieUsedStatus(id, newUsedStatus);

    };



    return (
        <>
            <MenuBarWorker />
            <div className="worker-container">


                <div className="movie-form">
    <h1>Film Hinzufuegen</h1>
    <section className="overview">             
    <input 
    type="text" 
    placeholder="Nach Filmen suchen..." 
    className="search-input" 
    value={searchTerm} 
    onChange={(e) => setSearchTerm(e.target.value)}
                   />
    </section>
    <h3>Dateneingabe</h3>
    <div className="input-row">

        <input 
            type="text" 
            placeholder="Movie ID Eingeben" 
            value={movieId} 
            onChange={(e) => setMovieId(e.target.value)} 
        />
        <input 
            type="text" 
            placeholder="YouTube URL Eingeben" 
            value={youtubeUrl} 
            onChange={(e) => setYoutubeUrl(e.target.value)} 
        />
        <input 
            type="text" 
            placeholder="FSK Einstufung eingeben" 
            value={fsk} 
            onChange={(e) => setFsk(e.target.value)} 
        />
    </div>
    <button 
        onClick={addMovie} 
        disabled={loading}
    >
        {loading ? 'Wird hinzugefuegt...' : 'Hinzufuegen'}
    </button> 


</div>

                <div className="movies-grid">
                {filteredMovies.map((movie) => (
                    <div key={movie.id} className="movie-card" onClick={() => handleMovieClick(movie.id)}>
                        <input 
                            type="checkbox" 
                            checked={movie.used} 
                            readOnly 
                            style={{ marginRight: '10px' }} 
                        />
                        <img 
                            src={`https://image.tmdb.org/t/p/w500${movie.poster_path}`} 
                            alt={movie.title} 
                            className="movie-poster" 
                        />
                        <span>{movie.title}</span>
                        <button 
                                onClick={() => deleteMovie(movie.id)}
                                className="delete-button" // Optional styling class
                            >
                                Löschen
                            </button>
                    </div>
                ))}
            </div>
            </div>
        </>
    );
}

export default FetchMovies;
