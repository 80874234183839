import React from 'react';
import MenuBar from '../../components/MenuBar';
import Footer from '../../components/Footer';
import '../../css/Footer/Datenschutz.css'


export default function Datenschutz() {
    return (
        <>
            <MenuBar />
            <div className="main-container">
                <h1>Datenschutzerklärung</h1>
                <div className="datenschutz-container">
                    <Section title="Überblick">
                        Der Schutz personenbezogener Daten und der verantwortungsvolle Umgang mit Informationen, die Du uns anvertraust, sind für MovieHub von größter Bedeutung. Wir, als Betreiber der Plattform, verarbeiten Deine personenbezogenen Daten nur in Übereinstimmung mit den gesetzlichen Regelungen, insbesondere der EU-Datenschutzgrundverordnung (DSGVO) und dem Bundesdatenschutzgesetz (BDSG). Diese Datenschutzerklärung informiert Dich darüber, wie, in welchem Umfang und zu welchen Zwecken wir bei der Nutzung der MovieHub-Website personenbezogene Daten verarbeiten.
                    </Section>

                    <Section title="§ 1 Verantwortlicher im Sinne des Datenschutzrechts">
                        MovieHub Entertainment GmbH
                        <br />
                        Beispielstraße 10
                        <br />
                        12345 Beispielstadt
                        <br />
                        E-Mail: info@moviehub.com
                    </Section>

                    <Section title="§ 2 Kontaktdaten des Datenschutzbeauftragten">
                        E-Mail: datenschutz@moviehub.com
                        <br />
                        MovieHub Entertainment GmbH
                        <br />
                        Beispielstraße 10
                        <br />
                        12345 Beispielstadt
                    </Section>

                    <Section title="§ 3 Verarbeitung Deiner personenbezogenen Daten">
                        Gegenstand des Datenschutzes sind personenbezogene Daten, also Informationen, die einer bestimmten oder bestimmbaren Person zugeordnet werden können, wie z.B. Name, Anschrift, E-Mail-Adresse und Telefonnummer.
                        <br />
                        Grundsätzlich ist für die Nutzung der MovieHub-Website die Verarbeitung personenbezogener Daten nicht erforderlich. Eine Datenerhebung und -verarbeitung erfolgt nur in den nachfolgend beschriebenen Fällen oder wenn Du uns freiwillig Deine Daten zur Verfügung stellst, etwa bei der Registrierung, dem Ticketkauf oder der Reservierung.
                    </Section>

                    <Section title="§ 3.1 Registrierung auf der MovieHub-Website">
                        Du kannst Dich auf unserer Website registrieren, um einen persönlichen Account anzulegen. Dabei erheben wir Daten wie Deine E-Mail-Adresse, Deinen Namen und Dein bevorzugtes Kino. Diese Daten werden gespeichert, um Dir die Nutzung der Website und den Ticketkauf zu erleichtern. Über Deinen Account kannst Du Deine Daten jederzeit ändern oder Deine bisherigen Ticketkäufe einsehen.
                    </Section>

                    <Section title="§ 3.2 Kauf von Gutscheinen und Artikeln im Onlineshop">
                        Beim Kauf von Gutscheinen oder anderen Artikeln im Onlineshop erheben wir Daten wie Deinen Namen, Deine Adresse, Deine E-Mail-Adresse und Deine Zahlungsdaten. Diese Informationen verwenden wir, um den Kaufvertrag zu erfüllen und Dir die bestellten Artikel zu liefern. Adressdaten geben wir nur an den beauftragten Lieferdienst (z.B. DHL) weiter.
                        <br />
                        Deine E-Mail-Adresse wird nicht an externe Dienstleister weitergegeben, es sei denn, dies ist für den Kaufvertrag notwendig.
                    </Section>

                    <Section title="§ 3.3 Kauf von Kino-Tickets im Onlineshop">
                        Beim Kauf von Kinotickets verwenden wir die von Dir angegebenen Daten (Name, Anschrift, E-Mail, Zahlungsinformationen) zur Abwicklung des Kaufvertrages. Dabei wird der Vertrag direkt zwischen Dir und dem jeweiligen Kinobetreiber abgeschlossen. MovieHub tritt hier lediglich als Vermittler auf.
                        <br />
                        Wenn Du eine Zahlungsoption wie Klarna wählst, übermitteln wir die Daten an den Zahlungsdienstleister zur Zahlungsabwicklung und Bonitätsprüfung. Verantwortlich für diese Datenverarbeitung ist Klarna. Mehr Informationen zu Klarnas Datenschutz findest Du auf deren Website.
                    </Section>

                    <Section title="§ 3.4 Reservierung">
                        Bei der Reservierung von Kinotickets speichern wir Deine Kontaktdaten, um die gewünschten Tickets zu reservieren. Falls MovieHub nicht selbst der Betreiber des Kinos ist, werden Deine Daten an den jeweiligen Betreiber weitergeleitet.
                    </Section>

                    <Section title="§ 4 Datenverarbeitung auf Grundlage berechtigter Interessen">
                        Wir verarbeiten personenbezogene Daten auf Grundlage berechtigter Interessen, etwa zur Verbesserung unserer Dienste oder zur Durchführung von Marketingmaßnahmen. Diese Datenverarbeitung basiert auf Art. 6 Abs. 1 lit. f) DSGVO und erfolgt, um Dir ein optimales Nutzererlebnis zu bieten.
                    </Section>

                    <Section title="§ 5 Anonyme Daten und Cookies">
                        Bei jedem Zugriff auf die MovieHub-Website werden automatisch allgemeine Informationen (z.B. Browsertyp, Zugriffszeit, Seitenaufrufe) erfasst. Diese Daten sind anonym und können nicht auf Dich persönlich zurückgeführt werden. Wir verwenden diese Informationen ausschließlich zu statistischen Zwecken und zur Verbesserung unserer Website.
                        <br />
                        Darüber hinaus nutzen wir Cookies, um die Benutzerfreundlichkeit unserer Website zu erhöhen. Cookies sind kleine Dateien, die auf Deinem Gerät gespeichert werden. Du kannst die Nutzung von Cookies in den Einstellungen Deines Browsers deaktivieren, allerdings kann dies zu Einschränkungen der Website-Funktionen führen.
                    </Section>

                    <Section title="§ 6 Datensicherheit">
                        MovieHub setzt technische und organisatorische Sicherheitsmaßnahmen ein, um Deine Daten vor Manipulation, Verlust oder unberechtigtem Zugriff zu schützen. Diese Maßnahmen werden regelmäßig überprüft und an den aktuellen Stand der Technik angepasst.
                    </Section>

                    <Section title="§ 7 Deine Rechte">
                        Du hast das Recht, jederzeit Auskunft über die bei uns gespeicherten personenbezogenen Daten zu verlangen. Darüber hinaus kannst Du die Berichtigung, Löschung oder Einschränkung der Verarbeitung Deiner Daten fordern. Ebenso steht Dir das Recht auf Datenübertragbarkeit und das Widerspruchsrecht gegen die Verarbeitung zu.
                        <br />
                        Um diese Rechte auszuüben, kontaktiere uns bitte über die in § 2 angegebenen Kontaktdaten.
                    </Section>

                    <Section title="§ 8 Datenschutz bei Drittanbietern">
                        MovieHub nutzt Dienste von Drittanbietern wie Zahlungsdienstleister oder Versandunternehmen. Dabei geben wir personenbezogene Daten nur weiter, soweit dies zur Erfüllung der Vertragsverhältnisse erforderlich ist. Diese Drittanbieter sind vertraglich dazu verpflichtet, die gesetzlichen Datenschutzbestimmungen einzuhalten.
                    </Section>

                    <Section title="§ 9 Änderungen der Datenschutzerklärung">
                        MovieHub behält sich das Recht vor, diese Datenschutzerklärung bei Bedarf anzupassen, etwa aufgrund geänderter gesetzlicher Vorgaben oder neuer technischer Entwicklungen. Die jeweils aktuelle Version der Datenschutzerklärung findest Du immer auf unserer Website.
                    </Section>

                    <Section title="§ 10 Schlussbestimmungen">
                        Diese Datenschutzerklärung gilt für alle Nutzer der MovieHub-Website. Sofern einzelne Bestimmungen dieser Erklärung unwirksam sein sollten, bleibt die Wirksamkeit der übrigen Bestimmungen davon unberührt.
                        <br />
                        Es gilt das Recht der Bundesrepublik Deutschland.
                    </Section>
                </div>
            </div>
            <Footer />
        </>
    );
}
const Section = ({ title, children }) => (
    <div className="agb-section">
        <h2>{title}</h2>
        <p>{children}</p>
    </div>
);
