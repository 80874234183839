import React from 'react';
import MenuBar from '../../components/MenuBar';
import Footer from '../../components/Footer';
import '../../css/Footer/AGB.css'


export default function AGB() {
    return (
        <>
            <MenuBar />
            <div className="main-container">
                <h1>Allgemeine Geschäftsbedingungen (AGB)</h1>
                <div className="agb-container">

                    <Section title="§ 1 Geltungsbereich">
                        (1) Diese Allgemeinen Geschäftsbedingungen (AGB) gelten für den Kauf von Kinotickets und die Nutzung der Plattform MovieHub.
                        <br />
                        (2) Sie regeln die Rechte und Pflichten zwischen MovieHub, dem Kunden und dem jeweiligen Kinobetreiber. Abweichende Bedingungen werden nicht anerkannt, es sei denn, sie werden schriftlich vereinbart.
                    </Section>

                    <Section title="§ 2 Vertragsabschluss, -parteien und -abwicklung">
                        (1) Der Vertrag über den Kauf von Kinotickets kommt zwischen dem Kunden und dem jeweiligen Kinobetreiber zustande. MovieHub vermittelt lediglich den Kaufprozess.
                        <br />
                        (2) Der Kaufvertrag wird durch die Bestätigung der Bestellung via E-Mail abgeschlossen. MovieHub stellt den Plattformbetrieb sicher, übernimmt jedoch keine Verantwortung für die Durchführung der Vorstellungen.
                        <br />
                        (3) Nach dem Kauf erhält der Kunde ein elektronisches Ticket (E-Ticket), das an der Kinokasse vorgelegt werden kann.
                    </Section>
                    <Section title="§ 3 Rechte und Pflichten des Kinobetreibers">
                        (1) Der Kinobetreiber verpflichtet sich, die gebuchten Vorstellungen durchzuführen, es sei denn, es treten unvorhersehbare Umstände (z.B. technische Defekte oder höhere Gewalt) auf.
                        <br />
                        (2) Der Kinobetreiber behält sich das Recht vor, Änderungen an den Vorstellungszeiten oder -inhalten vorzunehmen. In diesem Fall wird der Kunde rechtzeitig informiert und erhält gegebenenfalls eine Erstattung.
                    </Section>

                    <Section title="§ 4 Rechte und Pflichten des Kunden">
                        (1) Der Kunde ist verpflichtet, das erworbene Ticket an der Kinokasse vorzuzeigen und sich an die im Kino geltenden Hausregeln zu halten.
                        <br />
                        (2) Das Ticket ist nicht übertragbar und nur für den angegebenen Vorstellungszeitpunkt gültig.
                        <br />
                        (3) Der Kunde verpflichtet sich, keine Störungen während der Vorstellung zu verursachen und die Regeln des Kinos, insbesondere in Bezug auf Hygiene und Sicherheit, einzuhalten.
                    </Section>

                    <Section title="§ 5 Preise und Bezahlung">
                        (1) Die Preise für Kinotickets werden auf MovieHub angezeigt und verstehen sich inklusive der gesetzlichen Mehrwertsteuer.
                        <br />
                        (2) Die Bezahlung erfolgt über die von MovieHub angebotenen Zahlungsmethoden. Der Kaufpreis ist sofort nach Vertragsabschluss fällig.
                        <br />
                        (3) Eine Rückerstattung oder Stornierung des Tickets ist nur unter den in § 6 genannten Bedingungen möglich.
                    </Section>

                    <Section title="§ 6 Rückgabe, Erstattung und Widerruf, Vorstellungsausfall, Verschiebungen, Vorstellungsänderungen">
                        (1) Ein Widerrufsrecht für Kinotickets besteht gemäß § 312g Abs. 2 Nr. 9 BGB nicht, da es sich um Dienstleistungen im Freizeitbereich handelt, die für einen bestimmten Termin gebucht werden.
                        <br />
                        (2) Im Falle eines Vorstellungsausfalls oder einer erheblichen Änderung (z.B. Verschiebung auf einen anderen Tag) wird der Kunde umgehend informiert und erhält eine Erstattung oder kann auf eine andere Vorstellung umbuchen.
                        <br />
                        (3) Für geringfügige Änderungen (z.B. eine Verschiebung innerhalb des gleichen Tages) besteht kein Erstattungsanspruch.
                    </Section>

                    <Section title="§ 7 Allgemeine Rechte und Pflichten im Kino">
                        (1) Der Kunde ist verpflichtet, die Hausordnung des jeweiligen Kinos zu beachten. Dies umfasst insbesondere Regelungen zur Sicherheit, Sauberkeit und Rücksichtnahme auf andere Kinobesucher.
                        <br />
                        (2) Den Anweisungen des Kinopersonals ist Folge zu leisten. Bei Verstößen gegen die Hausordnung kann der Kunde des Kinos verwiesen werden, ohne Anspruch auf Erstattung des Ticketpreises.
                    </Section>

                    <Section title="§ 8 Verbot von Ton- und Bildaufnahmen, Vertragsstrafe">
                        (1) Das Aufzeichnen von Ton- und Bildmaterial während der Vorstellung ist strengstens untersagt.
                        <br />
                        (2) Bei Zuwiderhandlung behält sich der Kinobetreiber das Recht vor, den Kunden vom Besuch des Kinos auszuschließen und eine Vertragsstrafe von bis zu 1.000 € zu verlangen. Weitergehende Schadensersatzansprüche bleiben unberührt.
                    </Section>

                    <Section title="§ 9 Haftungsbeschränkung im Kino">
                        (1) Die Haftung des Kinobetreibers für Schäden, die nicht auf der Verletzung des Lebens, des Körpers oder der Gesundheit beruhen, ist auf Vorsatz und grobe Fahrlässigkeit beschränkt.
                        <br />
                        (2) Für einfache Fahrlässigkeit haftet der Kinobetreiber nur bei der Verletzung wesentlicher Vertragspflichten (Kardinalpflichten).
                        <br />
                        (3) MovieHub übernimmt keine Haftung für den Kinobesuch selbst, da es lediglich als Vermittler auftritt.
                    </Section>
                 
                    <Section title="§ 10 Datenschutz">
                        (1) MovieHub verarbeitet personenbezogene Daten des Kunden ausschließlich im Rahmen der geltenden Datenschutzgesetze.
                        <br />
                        (2) Detaillierte Informationen zur Datenverarbeitung und zu den Rechten des Kunden finden sich in der Datenschutzrichtlinie von MovieHub.
                        <br />
                        (3) Der Kunde hat das Recht, jederzeit Auskunft über die gespeicherten Daten zu verlangen sowie deren Löschung oder Berichtigung zu fordern.
                    </Section>

                    <Section title="§ 11 Schlussbestimmungen">
                        (1) Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts.
                        <br />
                        (2) Sollten einzelne Bestimmungen dieser AGB unwirksam sein oder werden, so bleibt die Wirksamkeit der übrigen Bestimmungen davon unberührt.
                        <br />
                        (3) Gerichtsstand ist der Sitz von MovieHub, sofern der Kunde Kaufmann ist.
                    </Section>
                    <p>Stand: Oktober 2024</p>
                </div>
            </div>
            <Footer />
        </>
    );
    
}

const Section = ({ title, children }) => (
    <div className="agb-section">
        <h2>{title}</h2>
        <p>{children}</p>
    </div>
);
