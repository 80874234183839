import React from 'react';
import { Link } from 'react-router-dom';
import MenuBarReporting from '../../components/MenuBarReporting';
import '../../css/worker/Ubersicht.css';



function Kundenverwaltung(){
return(
    <>
    <MenuBarReporting/>
    <div className="worker-container">
                <h1>Kundenverwaltung</h1>
                <nav>
                    <ul className="navigation-menu">
                    <li>
                            <Link to="/CreateDiscountCodes">Rabattcode Erstellung</Link>
                        </li>
                        <li>
                            <Link to="/DeleteDiscountCodes">Rabattcodes Löschen</Link>
                        </li>
                        <li>
                            <Link to="/AccountVerwalten">Admin-Accountverwaltung</Link>
                        </li>
                        <li>
                            <Link to="/NutzerVerwalten">Nutzerverwaltung</Link>
                        </li>

                    </ul>
                </nav>
            </div>

    
    
    
    
    
    
    </>
);
}
export default Kundenverwaltung;