import React, { useState, useEffect } from 'react';
import MenuBarWorker from "../../components/MenuBarWorker";
import '../../css/worker/VeranstaltungenErstellen.css';
import { API_URL } from '../../config';
import DatePicker from 'react-multi-date-picker';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const VeranstaltungErstellen = () => {
  const [loading, setLoading] = useState(false);
  const [movies, setMovies] = useState([]);
  const [saalNummer, setSaalNummer] = useState('');
  const [is3D, setIs3D] = useState(false);
  const [filmName, setFilmName] = useState('');
  const [dateTimeEntries, setDateTimeEntries] = useState([]);

  useEffect(() => {
    fetchMovies();
  }, []);

  const fetchMovies = async () => {
    setLoading(true);
    try {
      const response = await fetch(API_URL + '/filme/all', {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error('Netzwerk-Antwort war nicht ok');
      }
      const data = await response.json();

      const filteredMovies = data.filter(movie => movie.used === true);
      setMovies(filteredMovies);
    } catch (error) {
      console.error('Es gab ein Problem mit der Fetch-Anfrage:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddDateTimeEntry = () => {
    setDateTimeEntries([...dateTimeEntries, { date: null, time: null }]);
  };

  const handleRemoveDateTimeEntry = (index) => {
    const newEntries = [...dateTimeEntries];
    newEntries.splice(index, 1);
    setDateTimeEntries(newEntries);
  };

  const handleDateChange = (value, index) => {
    const formattedDate = dayjs(value).format('DD-MM-YYYY');  // Formatierung des Datums ohne ISO
    const newEntries = [...dateTimeEntries];
    newEntries[index].date = formattedDate;
    setDateTimeEntries(newEntries);
  };

  const handleTimeChange = (value, index) => {
    const newEntries = [...dateTimeEntries];
    newEntries[index].time = value;
    setDateTimeEntries(newEntries);
  };

  const handleSaalNummerChange = (event) => {
    setSaalNummer(event.target.value);
  };

  const handleFormatChange = (event) => {
    setIs3D(event.target.value === '3D');
  };

  const handleFilmChange = (event) => {
    const selectedFilmName = event.target.value;
    setFilmName(selectedFilmName);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (dateTimeEntries.length === 0) {
      alert('Bitte fügen Sie mindestens eine Vorstellung hinzu.');
      return;
    }

    const selectedFilm = movies.find(movie => movie.title === filmName);
    const selectedFilmId = selectedFilm ? selectedFilm.id : null;

    if (!selectedFilmId) {
      console.error('Film ID not found for the selected film name.');
      return;
    }

    for (const entry of dateTimeEntries) {
      console.log(entry.date +entry.time);
      if (!entry.date || !entry.time) {
        alert('Bitte stellen Sie sicher, dass alle Vorstellungen ein Datum und eine Zeit haben.');
        return;
      }

      const data = {
        date: entry.date,
        time: entry.time,
        saalNummer: saalNummer,
        format: is3D ? '3D' : '2D',
        film: String(selectedFilmId),
      };

      try {
        const response = await fetch(API_URL + "/Veranstaltungen", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });

        if (response.status === 403) {
          alert("Das Kino ist zu dieser Zeit geschlossen.");
          return;
        } else if (response.status === 409) {
          alert(`Ein Konflikt wurde gefunden: Ein anderer Film wird zur gleichen Zeit in Saal ${saalNummer} gespielt.`);
          return;
        } else if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      } catch (error) {
        console.error('Fehler:', error.message);
        return;
      }
    }

    alert('Vorstellungen hinzugefügt.');
  };

  return (
      <>
        <MenuBarWorker />
        <div className="worker-container">
          <h1>Vorstellung Hinzufügen</h1>
          <div className='VorstellungContainer'>
            <form onSubmit={handleSubmit}>
              <div>
                <label>Filmname: </label>
                <select onChange={handleFilmChange} required>
                  <option value="" disabled selected>
                    Bitte wählen Sie einen Film
                  </option>
                  {movies.map((movie, index) => (
                      <option key={index} value={movie.title}>
                        {movie.title}
                      </option>
                  ))}
                </select>
              </div>
              <div className='Saalnummer'>
                <label>Saalnummer: </label>
                <input
                    type="number"
                    value={saalNummer}
                    onChange={handleSaalNummerChange}
                    min="1"
                    required
                />
              </div>
              <div>
                <label>Format: </label>
                <select onChange={handleFormatChange}>
                  <option value="2D">2D</option>
                  <option value="3D">3D</option>
                </select>
              </div>

              <div className="date-time-entries">
                <h2>Vorstellungen</h2>
                {dateTimeEntries.map((entry, index) => (
                    <div key={index} className="date-time-entry">
                      <div>
                        <label>Datum: </label>
                        <DatePicker
                            value={entry.date}
                            onChange={(value) => handleDateChange(value, index)}
                            format="DD-MM-YYYY"
                            placeholder="Datum auswählen"
                        />
                      </div>
                      <div>
                        <label>Uhrzeit: </label>
                        <input
                            type="time"
                            value={entry.time}
                            onChange={(e) => handleTimeChange(e.target.value , index)}
                            required
                        />
                      </div>
                      <button type="button" onClick={() => handleRemoveDateTimeEntry(index)}>Entfernen</button>
                    </div>
                ))}
                <button type="button" onClick={handleAddDateTimeEntry}>Vorstellung hinzufügen</button>
              </div>

              <div className='Speichern'>
                <button type="submit">Speichern</button>
              </div>
            </form>
          </div>
        </div>
      </>
  );
};

export default VeranstaltungErstellen;
