import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom'; // Importiere useLocation
import '../../css/account/formular.css';
import { API_URL } from '../../config';
import MenuBar from '../../components/MenuBar';
import Footer from '../../components/Footer';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const location = useLocation(); // useLocation zum Auslesen des state-Parameters

    // `state` auslesen, falls vorhanden, ansonsten Standardwert setzen
    const from = location.state?.from || '/';
    const loginData = () => {
        const loginData = {
            email: email,
            password: password,
        };

        fetch(API_URL + '/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(loginData),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Login fehlgeschlagen');
                }
                return response.json();
            })
            .then((data) => {
                const { token } = data;
                if (token) {
                    sessionStorage.setItem('token', token);
                    console.log('Login erfolgreich!');
                    // Nach erfolgreichem Login zurück zur ursprünglichen Seite navigieren
                    navigate(from);
                    window.location.reload()
                } else {
                    console.error('Fehlende Anmeldedaten');
                }
            })
            .catch((error) => console.error('Fehler:', error));
    };

    return (
        <>
            <MenuBar/>
            <div className="main-container">

                <div className='formular-container'>
                    <h1>Willkommen zurück!</h1>
                </div>
                <div className="formular">
                    <input
                        type="email"
                        placeholder="E-Mail"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Passwort"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <button onClick={loginData}>Weiter</button>
                    <p>Noch keinen Account? <Link to="/account/register" state={{ from }}>Registrieren</Link></p>
                </div>
            </div>
            <Footer/>
        </>
    );
};

export default Login;