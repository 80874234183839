import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../../css/customer/SeatSelection.css';
import MenuBar from '../../components/MenuBar';
import Footer from '../../components/Footer';
import { API_URL } from '../../config';
import MovieDetails from './MovieDetails';

export default function SeatSelection() {
    const { id } = useParams(); // Verwende die URL-Parameter für die Vorstellungs-ID
    const navigate = useNavigate();

    const [seats, setSeats] = useState([]); // Sitzplätze aus der Datenbank
    const [bookedSeats, setBookedSeats] = useState([]); // Gebuchte Sitze (evtl. auch aus einer Buchungs-API)
    const [selectedSeats, setSelectedSeats] = useState([]); // Ausgewählte Sitze durch den Nutzer
    const [seatDiscounts, setSeatDiscounts] = useState({}); // Rabatte pro Sitzplatz
    const [showDetails, setShowDetails] = useState(null); // Details zur Vorstellung
    const [buchungsdetails, setBuchungsdetails] = useState([]);
    const [buchungen, setBuchungen] = useState([]);
    const [movie, setMovie] = useState(null);
    const [rabatte, setRabatte] = useState({
        kinderRabatt: 0,
        seniorenRabatt: 0,
        studentenRabatt: 0,
    });
    const [aufschlag, setAufschlag] = useState({
        logenAufschlag: 0,
        luxusLogenAufschlag: 0,
    });

    let price = 10;

    // Lade Vorstellungsdetails anhand der ID
    useEffect(() => {
        fetch(API_URL +`/Veranstaltungen/${id}`) // API-Endpunkt für Vorstellungsdetails
            .then(response => response.json())
            .then(data => {
                setShowDetails(data);
                
                // Sobald Vorstellung geladen ist, Film abrufen
                if (data.film) {
                    fetchMovieById(data.film); // Film-ID aus `data.film` verwenden
                }
            })
            .catch(error => console.error('Fehler beim Laden der Vorstellung:', error));
    }, [id]);

    const fetchMovieById = async (filmId) => {
        try {
            const response = await fetch(`${API_URL}/filme/findById/${filmId}`);
            if (!response.ok) {
                throw new Error('Fehler beim Laden der Filmdaten');
            }
            const data = await response.json();
            setMovie(data);

        } catch (error) {
            console.error('Fehler beim Laden der Filmdaten:', error);
        }
    };

    // Lade Sitzplatzdaten aus der Datenbank für die Saalnummer, nachdem showDetails geladen wurde
    useEffect(() => {
        if (showDetails && showDetails.saalNummer) {
            fetch(API_URL + `/SaalLaden/${showDetails.saalNummer}`) // API-Endpunkt basierend auf der Saalnummer
                .then(response => response.json())
                .then(data => {
                    setSeats(data); // Setze die Sitzplatzdaten
                })
                .catch(error => console.error('Fehler beim Laden der Sitzplätze:', error));
        }
    }, [showDetails]);

    //Lädt buchungen um zu überprüfen welche buchung zu der richtigen vorstellung gehört
    useEffect(() => {
        // API-Aufruf an den Backend-Endpunkt
        fetch(API_URL + '/Buchungen')
            .then((response) => response.json())
            .then((data) => {
                setBuchungen(data);
            })
            .catch((error) => {
                console.error('Fehler beim Abrufen der Buchungen:', error);
            });
    }, []);

    // Lade alle Buchungsdetails aus der Datenbank um zu überprüfen, ob die Sitze schon abgespeichert sind
    useEffect(() => {
        fetch(API_URL + '/Buchungsdetails') // API-Aufruf an den neuen Backend-Endpunkt
            .then((response) => response.json())
            .then((data) => {
                setBuchungsdetails(data);
            })
            .catch((error) => {
                console.error('Fehler beim Abrufen der Buchungsdetails:', error);
            });
    }, []);
    // Lade Rabatte aus der Datenbank
    useEffect(() => {
        fetch(API_URL + '/Preise')
            .then((response) => response.json())
            .then((data) => {
                setRabatte({
                    kinderRabatt: data.kinderRabatt,
                    seniorenRabatt: data.seniorenRabatt,
                    studentenRabatt: data.studentenRabatt,
                });
                setAufschlag({
                    logenAufschlag: data.logenAufschlag,
                    luxusLogenAufschlag: data.luxusLogenAufschlag,
                })
                price = data.basisPreis;
            })
            .catch((error) => console.error('Fehler beim Abrufen der Rabatte:', error));
    }, []);

    // Sitzplätze nach Reihen organisieren
    const getSeatsByRow = () => {
        const rows = {};

        // Iteriere über alle Sitzplätze und ordne sie nach Reihen
        seats.forEach(seat => {
            if (!rows[seat.reihe]) {
                rows[seat.reihe] = [];
            }
            rows[seat.reihe].push(seat);
        });

        // Sortiere die Sitzplätze innerhalb der Reihe nach Nummer
        Object.keys(rows).forEach(row => {
            rows[row].sort((a, b) => a.nummer - b.nummer);
        });

        // Sortiere die Reihen
        return Object.keys(rows)
            .sort()
            .reduce((acc, key) => {
                acc[key] = rows[key];
                return acc;
            }, {});
    };

    //überprüfen ob sitz schon gebucht ist
    const booked = (sitzId) => {
        let place = false
        let j;
        for (j = 0; j < buchungen.length; j++) {
            if (buchungen[j].vorstellung_id.toString() === id.toString()) {
                let i;
                for (i = 0; i < buchungsdetails.length; i++) {
                    if (sitzId === buchungsdetails[i].sitz_id && buchungen[j].buchung_id === buchungsdetails[i].buchung_id) {
                        place = true;
                    }
                }
            }
        }
        return place;
    };
    // Sitz auswählen oder abwählen
    const handleSeatClick = (seat) => {
        const seatIdentifier = `${seat.reihe}${seat.nummer}`;
        if (bookedSeats.includes(seatIdentifier) || seat.typ === 'gang') return; // Sitz ist bereits gebucht oder Gang

        if (selectedSeats.includes(seatIdentifier)) {
            setSelectedSeats(selectedSeats.filter((s) => s !== seatIdentifier)); // Sitz abwählen
            setSeatDiscounts((prev) => {
                const updated = { ...prev };
                delete updated[seatIdentifier];
                return updated;
            });
        } else {
            setSelectedSeats([...selectedSeats, seatIdentifier]); // Sitz auswählen
            console.log('Sitz ausgewählt:', seatIdentifier); // Hinzufügen zur Konsole
            setSeatDiscounts((prev) => ({
                ...prev,
                [seatIdentifier]: 'normal' // Standardmäßig keine Ermäßigung
            }));
        }
    };
    // Rabatt für jeden Sitz aktualisieren
    const handleDiscountChange = (seat, discount) => {
        setSeatDiscounts((prev) => ({
            ...prev,
            [seat]: discount
        }));
    };

    // Berechnung des Preises basierend auf Rabatten und Kategorie-Aufschlägen
    const getPrice = (seat, discount) => {
        let finalPrice = price;
        console.log(seat.kategorie);
        // Überprüfen, ob ein prozentualer Aufschlag auf den Sitzplatz angewendet werden muss
        if (seat.kategorie === 'loge') {
            finalPrice += (finalPrice * aufschlag.logenAufschlag) / 100; // Logen-Aufschlag in Prozent
        } else if (seat.kategorie === 'premium') {
            finalPrice += (finalPrice * aufschlag.luxusLogenAufschlag) / 100; // Luxus-Logen-Aufschlag in Prozent
        }

        // Rabatte anwenden
        switch (discount) {
            case 'child':
                return finalPrice - (finalPrice * rabatte.kinderRabatt) / 100;
            case 'senior':
                return finalPrice - (finalPrice * rabatte.seniorenRabatt) / 100;
            case 'student':
                return finalPrice - (finalPrice * rabatte.studentenRabatt) / 100;
            default:
                return finalPrice; // Normaler Preis
        }
    };

    const totalPrice = selectedSeats.reduce((total, seatIdentifier) => {
        // Finde den Sitz basierend auf dem Identifier
        const seat = seats.find(s => `${s.reihe}${s.nummer}` === seatIdentifier);
        // Falls der Sitz nicht gefunden wird, überspringen
        if (!seat) {
            console.error(`Sitz mit Identifier ${seatIdentifier} nicht gefunden.`);
            return total;
        }

        // Berechne den Preis für den Sitz unter Berücksichtigung von Rabatten und Aufschlägen
        return total + getPrice(seat, seatDiscounts[seatIdentifier]);
    }, 0);

    const proceedToPayment = () => {
        const seatCategoriesAndIds = selectedSeats.map((seatIdentifier) => {
            const seat = seats.find(s => `${s.reihe}${s.nummer}` === seatIdentifier);
            return {
                reihe: seat.reihe,
                nummer: seat.nummer,
                category: seat.kategorie,
                seatId: seat.sitz_id,
            };
        });
        navigate(`/checkout`, {
            state: {
                selectedSeats,
                seatCategoriesAndIds,
                seatDiscounts,
                totalPrice,
                movieTitle: movie.title,
                date: showDetails.datum,
                time: showDetails.uhrzeit,
                room: showDetails.saalNummer,
                id: id
            },
        });
    };


    if (!showDetails) {
        return <p>Lädt...</p>; // Anzeige während des Ladens der Vorstellung
    }

    const rows = getSeatsByRow(); // Sitzplätze nach Reihen organisiert

    // Funktion zur Bestimmung der Klasse eines Sitzes
    const getSeatClassName = (seat) => {
        const seatIdentifier = `${seat.reihe}${seat.nummer}`;
        let className = 'seat';
        if (seat.kategorie === 'gang') {
            className += ' aisle';
        } else if (selectedSeats.includes(seatIdentifier)) {
            className += ' selected';
        } else {
            className += ` ${seat.kategorie}`;
        }
        if (booked(seat.sitz_id)) {
            className += ' booked';
        }
        return className;
    };

    return (
        <>
            <MenuBar />
            <div className="main-container">
                <div className="seat-selection-page">
                    {/* Hauptinhalt */}
                    <div className="seat-selection">
                         {/* Zeige den Titel nur, wenn `movie.title` definiert ist */}
                    {movie?.title && <h1>Sitzplatzwahl für {movie.title}</h1>}
                        <p>Vorstellung am {showDetails.datum} um {showDetails.uhrzeit} Uhr in Saal {showDetails.saalNummer}</p>

                        <div className="seat-selection-content">
                            <div className="seat-map">
                                <div className="screen">Leinwand</div>

                                <div className="seat-grid">
                                    {Object.keys(rows).map((reihe, rowIndex) => (
                                        <div key={rowIndex} className="seat-row">
                                            {/* Sitzplätze ohne Reihe oder Nummer anzeigen */}
                                            {rows[reihe].map((seat, seatIndex) => (
                                                <button
                                                    key={seatIndex}
                                                    className={getSeatClassName(seat)} // Dynamische Klasse für Sitztypen
                                                    onClick={() => handleSeatClick(seat)}
                                                    disabled={seat.kategorie === 'none' || bookedSeats.includes(seat.sitz_id) || seat.kategorie === 'gang' || booked(seat.sitz_id)} // Gänge und gebuchte Sitze deaktivieren
                                                >
                                                    {/* Keine Nummer oder Text für die Sitze */}
                                                </button>
                                            ))}
                                        </div>
                                    ))}
                                </div>

                                <div className="legend">
                                    <div><span className="legend-seat classic"></span> Classic</div>
                                    <div><span className="legend-seat premium"></span> Premium</div>
                                    <div><span className="legend-seat loge"></span> Loge</div>
                                    <div><span className="legend-seat disabled"></span> Barrierefrei</div>
                                    <div><span className="legend-seat booked"></span> Gebucht</div>
                                    <div><span className="legend-seat selected"></span> Ausgewählt</div>
                                </div>
                            </div>

                            <div className="discount-box">
                                <h3>Ermäßigung für ausgewählte Sitze:</h3>
                                {selectedSeats.length > 0 ? (
                                    selectedSeats.map((seatIdentifier) => {
                                        const seat = seats.find(s => `${s.reihe}${s.nummer}` === seatIdentifier);

                                        return (
                                            <div key={seatIdentifier} className="discount-item">
                                                <label>{seatIdentifier}:</label>
                                                <select
                                                    value={seatDiscounts[seatIdentifier] || 'normal'}
                                                    onChange={(e) => handleDiscountChange(seatIdentifier, e.target.value)}
                                                >
                                                    <option value="normal">Normal ({getPrice(seat, 'normal').toFixed(2)}€)</option>
                                                    <option value="child">Kind ({getPrice(seat, 'child').toFixed(2)}€)</option>
                                                    <option value="senior">Senior ({getPrice(seat, 'senior').toFixed(2)}€)</option>
                                                    <option value="student">Student ({getPrice(seat, 'student').toFixed(2)}€)</option>
                                                </select>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <p>Keine Sitze ausgewählt.</p>
                                )}

                                <div className="selection-info">
                                    <h3>Gesamtpreis: {totalPrice.toFixed(2)}€</h3>
                                    <button
                                        className="confirm-button"
                                        disabled={selectedSeats.length === 0}
                                        onClick={proceedToPayment}
                                    >
                                        Zur Zahlung fortfahren
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}