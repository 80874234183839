import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/customer/home';
import MovieDetails from './pages/customer/MovieDetails';
import SeatSelection from './pages/customer/SeatSelection'; // Sitzplatzwahl importieren
import Checkout from './pages/customer/Checkout'; // Checkout-Seite importieren
import PurchaseComplete from './pages/customer/PurchaseComplete'; // Neue Seite für Kaufabschluss importieren
import Feedback from './pages/reporting/Feedback';
import Kundendaten from './pages/reporting/Kundendaten';
import ReportingHome from './pages/reporting/ReportingHome';
import StornoGrund from './pages/reporting/StornoGrund';
import Umsatzgraph from './pages/reporting/Umsatzgraph';
import VeranstaltungErstellen from './pages/worker/VeranstaltungErstellen';
import Preisgestaltung from './pages/worker/Preisgestaltung';
import UbersichtWorker from './pages/worker/UebersichtWorker';
import Verwaltung from './pages/worker/Verwaltung';
import VeranstaltungLoeschen from './pages/worker/VeranstaltungLoeschen';
import Angebote from './pages/customer/Angebote';
//import Events from './pages/customer/Events';
//import Eventerstellen from './pages/worker/Eventerstellen';
//import Eventloeschen from './pages/worker/Eventloeschen';
import ManageAccount from './pages/account/manageAccount';
import Login from './pages/account/login';
import Register from './pages/account/register';
import SaalEditor from './pages/worker/Saaleditor';
import SaalLöschen from './pages/worker/Saallöschen';
import NutzerVerwalten from './pages/worker/Nutzerverwalten';
import BookingOverview from './pages/worker/BookingOverview';
import Cancellation from "./pages/account/Cancellation";
import AddTestdata from "./pages/reporting/AddTestdata";
import CreateDiscountCodes from "./pages/worker/CreateDiscountCodes";
import DeleteDiscountCodes from "./pages/worker/DeleteDiscountCodes";
import { AuthProvider } from './components/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Oeffnungszeiten from './pages/worker/OeffnungszeitenBearbeiten';
import Kundenverwaltung from './pages/worker/Kundenverwaltung';

// Importiere die Footer-Seiten
import AGB from './pages/Footer/AGB';
import Datenschutz from './pages/Footer/Datenschutz';
import FAQ from './pages/Footer/FAQ';
import Impressum from './pages/Footer/Impressum';
import Kontakt from './pages/Footer/Kontakt';
import FetchMovies from './pages/worker/Filme';
import AdminAccountErstellen from "./pages/worker/AdminAccountserstellen";



export default function App() {
    return (
        <div>
            <AuthProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Home />} /> {/* Startseite */}
                        <Route path="/movie/:name" element={<MovieDetails />} /> {/* Detailseite */}
                        <Route path="/seats/:id" element={<SeatSelection />} /> {/* Sitzplatzwahl mit Datum */}
                        <Route path="/checkout" element={<Checkout />} /> {/* Checkout-Seite */}
                        <Route path="/purchase-complete" element={<PurchaseComplete />} /> {/* Kaufabschluss-Seite */}
                        <Route path="/Angebote" element={<Angebote />} /> 
                        {/* <Route path="/Events" element={<Events />} />*/}
                        <Route path="/AddTestdaten" element={<AddTestdata />} />


                        {/* Worker Routes */}
                        <Route
                            path="/VeranstaltungenErstellen"
                            element={<ProtectedRoute element={<VeranstaltungErstellen />} allowedRoles={['worker', 'admin']} />}
                        />
                        <Route
                            path="/CreateDiscountCodes"
                            element={<ProtectedRoute element={<CreateDiscountCodes />} allowedRoles={['worker', 'admin']} />}
                        />
                        <Route
                            path="/DeleteDiscountCodes"
                            element={<ProtectedRoute element={<DeleteDiscountCodes />} allowedRoles={['worker', 'admin']} />}
                        />
                        <Route
                            path="/VeranstaltungenLoeschen"
                            element={<ProtectedRoute element={<VeranstaltungLoeschen />} allowedRoles={['worker', 'admin']} />}
                        />

                        {/*<Route path='/Eventerstellen' element={<ProtectedRoute element={<Eventerstellen />} allowedRoles={['worker', 'admin']} />} /> */}
                        {/*<Route path='/Eventloeschen' element={<ProtectedRoute element={<Eventloeschen />} allowedRoles={['worker', 'admin']} />} /> */}
                        <Route path='/AccountVerwalten' element={<AdminAccountErstellen/>}/>
                        <Route
                            path="/Preisgestaltung"
                            element={<ProtectedRoute element={<Preisgestaltung />} allowedRoles={['worker', 'admin']} />}
                        />
                        <Route
                            path="/Ubersicht"
                            element={<ProtectedRoute element={<UbersichtWorker />} allowedRoles={['worker', 'admin']} />}
                        />
                        <Route
                            path="/Verwaltung"
                            element={<ProtectedRoute element={<Verwaltung />} allowedRoles={['worker', 'admin']} />}
                        />
                        <Route
                            path="/Saaleditor"
                            element={<ProtectedRoute element={<SaalEditor />} allowedRoles={['worker', 'admin']} />}
                        />
                        <Route 
                            path='/Saalloeschen'
                            element={<ProtectedRoute element={<SaalLöschen />} allowedRoles={['worker', 'admin']} /> }
                        />
                        <Route
                            path="/Nutzerverwalten"
                            element={<ProtectedRoute element={<NutzerVerwalten />} allowedRoles={['admin']} />}
                        />
                        <Route
                            path="/buchungen"
                            element={<ProtectedRoute element={<BookingOverview />} allowedRoles={['worker', 'admin']} />}
                        />
                        <Route
                            path="/Filme"
                            element={<ProtectedRoute element={<FetchMovies />} allowedRoles={['worker', 'admin']} />}
                        />
                        <Route
                            path="/Oeffnungszeiten"
                            element={<ProtectedRoute element={<Oeffnungszeiten />} allowedRoles={['worker', 'admin']} />}
                        />
                        <Route
                            path="/Kundenverwaltung"
                            element={<ProtectedRoute element={<Kundenverwaltung/>} allowedRoles={['worker', 'admin']} />}
                        />

                        {/* Reporting Routes */}
                        <Route
                            path="/Feedback"
                            element={<ProtectedRoute element={<Feedback />} allowedRoles={['reporting', 'admin']} />}
                        />
                        <Route
                            path="/Kundendaten"
                            element={<ProtectedRoute element={<Kundendaten />} allowedRoles={['reporting', 'admin']} />}
                        />
                        <Route
                            path="/ReportingHome"
                            element={<ProtectedRoute element={<ReportingHome />} allowedRoles={['reporting', 'admin']} />}
                        />
                        <Route
                            path="/StornoGrund"
                            element={<ProtectedRoute element={<StornoGrund />} allowedRoles={['reporting', 'admin']} />}
                        />
                        <Route
                            path="/Umsatzgraph"
                            element={<ProtectedRoute element={<Umsatzgraph />} allowedRoles={['reporting', 'admin']} />}
                        />
                        
                        {/* Footer Pages */}
                        <Route path="/AGB" element={<AGB />} /> {/* AGB-Seite */}
                        <Route path="/Datenschutz" element={<Datenschutz />} /> {/* Datenschutz-Seite */}
                        <Route path="/FAQ" element={<FAQ />} /> {/* FAQ-Seite */}
                        <Route path="/Impressum" element={<Impressum />} /> {/* Impressum-Seite */}
                        <Route path="/Kontakt" element={<Kontakt />} /> {/* Kontakt-Seite */}

                        {/* Account Pages */}
                        <Route path="/Login" element={<Login />} /> {/* Login-Seite */}
                        <Route path="/account/register" element={<Register />} /> {/* Register-Seite */}
                        <Route
                            path="/account/manageAccount"
                            element={<ProtectedRoute element={<ManageAccount />} allowedRoles={['customer', 'admin']} />}
                        />
                        <Route
                            path="/account/stonierungen"
                            element={<ProtectedRoute element={<Cancellation />} allowedRoles={['customer', 'admin']} />}
                        />
                    </Routes>
                </BrowserRouter>
            </AuthProvider>
        </div>
    );
}