import React, { useEffect, useState } from 'react';
import MenuBarWorker from '../../components/MenuBarWorker';
import '../../css/worker/VeranstaltungenLoeschen.css';
import { API_URL } from '../../config';

const VeranstaltungenLoeschen = () => {
    const [veranstaltungen, setVeranstaltungen] = useState([]);
    const [selectedVeranstaltungen, setSelectedVeranstaltungen] = useState([]);
    const [searchTerm, setSearchTerm] = useState(''); // für die Suchanfrage

    useEffect(() => {
        // Fetch Veranstaltungen from Javalin backend without axios
        fetch(API_URL + '/Veranstaltungen')
            .then(response => response.json())
            .then(data => {
                setVeranstaltungen(data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const handleSelect = (id) => {
        setSelectedVeranstaltungen(prevSelected =>
            prevSelected.includes(id)
                ? prevSelected.filter(item => item !== id)
                : [...prevSelected, id]
        );
    };

    const selectall = () => {
        if (selectedVeranstaltungen.length === veranstaltungen.length) {
            setSelectedVeranstaltungen([]);
        } else {
            setSelectedVeranstaltungen(veranstaltungen.map(veranstaltung => veranstaltung.id));
        }
    };

    const handleDelete = () => {
        const deleteRequests = selectedVeranstaltungen.map(id =>
            fetch(API_URL + `/Veranstaltungen/${id}`, {
                method: 'DELETE'
            })
        );

        Promise.all(deleteRequests)
            .then(() => {
                setVeranstaltungen(prevVeranstaltungen =>
                    prevVeranstaltungen.filter(veranstaltung =>
                        !selectedVeranstaltungen.includes(veranstaltung.id)
                    )
                );
                setSelectedVeranstaltungen([]);
            })
            .catch(error => {
                console.error('Error deleting data:', error);
            });
    };

    // Filtere die Veranstaltungen basierend auf der Suchanfrage
    const filteredVeranstaltungen = veranstaltungen.filter(veranstaltung =>
        veranstaltung.id.toString().includes(searchTerm) // suche nach ID
    );

    return (
        <>
            <MenuBarWorker />
            <div className='worker-container'>
                <h1>Veranstaltungen Loeschen</h1>
                <div className='search-container'>
                    <input
                        type="text"
                        placeholder="Suche nach ID..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                <div className='Tasten'>
                    <button onClick={handleDelete}>Loeschen</button>
                    <button onClick={selectall}>Alle Auswaehlen</button>
                </div>
                <div className='tabellenContainer'>
                    <table>
                        <thead>
                            <tr>
                                <th>Auswaehlen</th>
                                <th>ID</th>
                                <th>Film</th>
                                <th>Datum</th>
                                <th>Zeit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredVeranstaltungen.map((veranstaltung) => (
                                <tr key={veranstaltung.id}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={selectedVeranstaltungen.includes(veranstaltung.id)}
                                            onChange={() => handleSelect(veranstaltung.id)}
                                        />
                                    </td>
                                    <td>{veranstaltung.id}</td>
                                    <td>{veranstaltung.film}</td>
                                    <td>{veranstaltung.datum}</td>
                                    <td>{veranstaltung.uhrzeit}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default VeranstaltungenLoeschen;
