import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../../css/customer/MovieDetails.css';
import MenuBar from "../../components/MenuBar";
import Footer from '../../components/Footer';
import { API_URL } from '../../config';
import { AuthContext } from "../../components/AuthContext";
import { parse, isAfter } from 'date-fns';

export default function MovieDetails() {
    const { name } = useParams();
    const navigate = useNavigate();
    const [isDescriptionOpen, setDescriptionOpen] = useState(false);
    const [allShowtimes, setAllShowtimes] = useState([]);
    const [trailer, setTrailer] = useState("https://www.youtube.com/embed/dQw4w9WgXcQ");
    const { email } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [movie, setMovie] = useState(null);

    useEffect(() => {
        const fetchMovieById = async (id) => {
            try {
                const response = await fetch(`${API_URL}/filme/findById/${id}`);
                if (!response.ok) throw new Error('Error loading movie data');
                const data = await response.json();
                setMovie(data);
                setTrailer(data.youtube_url);
                setLoading(false);
            } catch (error) {
                console.error('Error loading movie data:', error);
                setLoading(false);
            }
        };
        if (name) fetchMovieById(name);
    }, [name]);

    useEffect(() => {
        const fetchShowtimes = async () => {
            try {
                console.log(`Fetching showtimes for movie: ${name}`);
                const response = await fetch(`${API_URL}/Veranstaltungen`);
                if (!response.ok) throw new Error(`Error loading showtimes data: ${response.status}`);
                const data = await response.json();
                
                // Filter to include only future showtimes
                console.log(data);
                const filteredData = data.filter(show => {
                    const [day, month, year] = show.datum.split('-');
                    const showDateTime = new Date(`${year}-${month}-${day}-${show.uhrzeit}`);
                    return String(show.film) === name.toString() && isAfter(showDateTime, new Date());
                });
                console.log(filteredData);
                // Sort filtered showtimes by date
                filteredData.sort((a, b) => {
                    const dateA = new Date(`${a.datum} ${a.uhrzeit}`);
                    const dateB = new Date(`${b.datum} ${b.uhrzeit}`);
                    return dateA - dateB;
                });

                setAllShowtimes(filteredData);
                console.log(filteredData);
            } catch (error) {
                console.error('Error:', error.message);
            }
        };
        fetchShowtimes();
    }, [name]);

    const handleTimeClick = (show) => {
        if (show && show.id) {
            navigate(`/seats/${show.id}`);
        } else {
            console.error('Show data is missing critical properties:', show);
        }
    };

    const toggleDescription = () => {
        setDescriptionOpen(!isDescriptionOpen);
    };

    const groupShowtimesByDateAndRoom = (showtimes) => {
        const grouped = {};

        showtimes.forEach((show) => {
            const showDate = parse(show.datum, 'dd-MM-yyyy', new Date());
            const formattedDate = showDate.toLocaleDateString('de-DE', { weekday: 'short', day: '2-digit', month: '2-digit' });
            const roomKey = `Kino ${show.saalNummer}`;

            if (!grouped[formattedDate]) grouped[formattedDate] = {};
            if (!grouped[formattedDate][roomKey]) grouped[formattedDate][roomKey] = [];

            grouped[formattedDate][roomKey].push(show);
        });

        return grouped;
    };

    const groupedShowtimes = groupShowtimesByDateAndRoom(allShowtimes);

    return (
        <>
            <MenuBar />
            <div className="main-container">
                {movie ? (
                    <>
                        <h1>{movie.title}</h1>
                        <div className="movie-container">
                            <div className="movie-info-left">
                                <div className="trailer-container">
                                    <iframe
                                        width="560"
                                        height="315"
                                        src={trailer}
                                        title="YouTube Video Player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                </div>
                                <img
                                    src={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
                                    alt="Filmplakat"
                                    className="movie-poster"
                                />
                                <div className="movie-details-info">
                                    <p>Filmstart: {movie.release_date}</p>
                                    <p>Laufzeit: {movie.runtime} Minuten</p>
                                    <p>Genre: {movie.genres}</p>
                                    <p>FSK: {movie.fsk}</p>
                                </div>
                                <div className="movie-description">
                                    <button onClick={toggleDescription}>
                                        {isDescriptionOpen ? "Beschreibung ausblenden" : "Beschreibung anzeigen"}
                                    </button>
                                    {isDescriptionOpen && (
                                        <p>{movie.overview}</p>
                                    )}
                                </div>
                            </div>

                            {/* Showtimes list without date selection */}
                            <div className="showtimes">
                                {Object.entries(groupedShowtimes).length > 0 ? (
                                    Object.entries(groupedShowtimes).map(([date, rooms]) => (
                                        <div key={date} className="showtime-date">
                                            <h4>{date}</h4>
                                            {Object.entries(rooms).map(([room, shows]) => (
                                                <div key={room} className="showtime-room">
                                                    <h5>{room}</h5>
                                                    {shows.map((show, idx) => (
                                                        <button
                                                            key={idx}
                                                            className="time-button"
                                                            onClick={() => handleTimeClick(show)}
                                                        >
                                                            {parse(show.uhrzeit, 'HH:mm', new Date()).toLocaleTimeString([], {
                                                                hour: '2-digit',
                                                                minute: '2-digit'
                                                            })} {show.version}
                                                        </button>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                    ))
                                ) : (
                                    <p>Keine zukünftigen Vorstellungen verfügbar.</p>
                                )}
                            </div>
                        </div>
                        <div className="movie-reviews">
                            <h2>Bewertungen</h2>
                            <p>Hier könnten Benutzer ihre Bewertungen abgeben.</p>
                        </div>
                    </>
                ) : (
                    <p>Lade Filmdaten...</p>
                )}
            </div>
            <Footer />
        </>
    );
}
