import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../css/customer/home.css';
import MenuBar from '../../components/MenuBar';
import Footer from '../../components/Footer';
import { API_URL } from '../../config';

export default function Home() {
    const [screenings, setScreenings] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [movies, setMovies] = useState([]);
    const [Veranstaltungen, setVeranstaltungen] = useState([]);

    useEffect(() => {
        // Function to fetch movies and screenings from the database
        const fetchScreeningToday = async () => {
            try {
                const response = await fetch(API_URL + '/VeranstaltungenHeute');
                if (!response.ok) {
                    throw new Error('Error loading movie data');
                }
                const data = await response.json();
                setVeranstaltungen(data);
            } catch (error) {
                console.error('Error loading movie data:', error);
                setLoading(false);
            }
        }
        const fetchMoviesAndScreenings = async () => {
            try {
                const response = await fetch(API_URL + '/filme/used');
                if (!response.ok) {
                    throw new Error('Error loading movie data');
                }
                const data = await response.json();
                setMovies(data);
            } catch (error) {
                console.error('Error loading movie data:', error);
                setLoading(false);
            }
            try {
                const response = await fetch(API_URL + '/Veranstaltungen');
                if (!response.ok) {
                    throw new Error('Error loading screenings');
                }
                const data = await response.json();
                setScreenings(data);
            } catch (error) {
                console.error('Error loading screenings data:', error);
                setLoading(false);
            }
            setLoading(false);
        };
        fetchScreeningToday();

        fetchMoviesAndScreenings();
    }, []);

    // Function to find the movie by ID from movies
    const findMovieById = (movieId) => {
        return movies.find(movie => movie.id === movieId);
    };

    // Group screenings by movie and collect all formats
    const uniqueMoviesWithFormats = screenings.reduce((acc, screening) => {
        const movie = findMovieById(screening.film);
        if (movie) {
            if (!acc[screening.film]) {
                acc[screening.film] = {
                    movie,
                    formats: new Set(),
                };
            }
            acc[screening.film].formats.add(screening.is3D ? '3D' : '2D');
        }
        return acc;
    }, {});

    // Convert the formats Set to an array and get unique movies
    const uniqueMovies = Object.values(uniqueMoviesWithFormats).map(({ movie, formats }) => ({
        ...movie,
        formats: Array.from(formats),
    }));

    // Filter movies by search term
    const filteredMovies = uniqueMovies.filter((movie) =>
        movie.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            <MenuBar />
            <div className="main-container">
                <h1>Unser aktuelles Programm!</h1>

                {/* Search Bar */}
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder="Suche Filme..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                <h1>Alle Filme</h1>

                {/* Movie Grid */}
                <div className="movie-container">
                    <div className="movie-grid">
                        {loading ? (
                            <p>Lädt Filme...</p>
                        ) : (
                            filteredMovies.length > 0 ? (
                                filteredMovies.map((movie) => (
                                    <div key={movie.id}>
                                        <div className="movie-card">
                                            <Link to={`/movie/${movie.id}`}>
                                                <img
                                                    src={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
                                                    alt={movie.title}
                                                />
                                            </Link>
                                        </div>
                                        <div className="movie-info">
                                            <Link to={`/movie/${movie.id}`}>
                                                <h3>{movie.title}</h3>
                                            </Link>
                                            <p>Format: {movie.formats.join(' & ')}</p>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>Keine Filme gefunden.</p>
                            )
                        )}
                    </div>
                </div>
                <h1>Heutige Veranstaltungen</h1>

                {/* Today's Screenings Section (moved below the movie container) */}
                <section className="movie-container">
    <div className="movie-grid">
        {Veranstaltungen.length > 0 ? (
            Veranstaltungen.map((screening) => {
                const movie = findMovieById(screening.film);
                return movie ? (
                    <div key={screening.id} className="movie-container-item">
                        <div className="movie-card">
                            <Link to={`/seats/${screening.id}`}>
                                <img
                                    src={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
                                    alt={movie.title}
                                />
                            </Link>
                        </div>
                        <div className="movie-info">
                            <Link to={`/seats/${screening.id}`}>
                                <h3>{movie.title}</h3>
                            </Link>
                            <p>Uhrzeit: {screening.uhrzeit}</p>
                            <p>Format: {screening.is3D ? '3D' : '2D'}</p>
                            <p>Saal: {screening.saalNummer}</p>
                        </div>
                    </div>
                ) : null;
            })
        ) : (
            <p>Keine Filme für heute gefunden.</p>
        )}
    </div>
</section>
            </div>
            <Footer />
        </>
    );
}