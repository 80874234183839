import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../css/account/manageAccount.css';
import MenuBar from '../../components/MenuBar';
import Footer from '../../components/Footer';
import { API_URL } from "../../config";
import { AuthContext } from "../../components/AuthContext";

const ManageAccount = () => {
    const [loyaltyPoints, setLoyaltyPoints] = useState(0);
    const [tickets, setTickets] = useState([]);
    const [vouchers, setVouchers] = useState([]);
    const [offers, setOffers] = useState([]);
    const [userData, setUserData] = useState({ firstName: '', lastName: '', email: '' });
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState(null);

    const navigate = useNavigate();
    const { userId, logout } = useContext(AuthContext);
    const kundeId = userId || 0;

    // Fetch loyalty points from the database
    useEffect(() => {
        if (kundeId) {
            fetchLoyaltyPoints();
        }
    }, [kundeId]);

    // Fetch vouchers from the database
    useEffect(() => {
        if (kundeId) {
            fetchVouchers();
        }
    }, [kundeId]);

    // Fetch available discount codes for the user
    useEffect(() => {
        fetch(`${API_URL}/getCodes`)
            .then((response) => response.json())
            .then((data) => setOffers(data))
            .catch((error) => {
                console.error('Fehler beim Abrufen der Angebote:', error);
                setError('Fehler beim Abrufen der Angebote');
            });
    }, [kundeId]);

    // Fetch user data
    useEffect(() => {
        if (kundeId) {
            fetch(`${API_URL}/user/${kundeId}`)
                .then((response) => response.json())
                .then((data) => setUserData(data))
                .catch((error) => {
                    console.error('Fehler beim Abrufen der Benutzerdaten:', error);
                    setError('Fehler beim Abrufen der Benutzerdaten');
                });
        }
    }, [kundeId]);

    const handleRedeemOffer = async (offerId, pointsRequired) => {
        if (loyaltyPoints >= pointsRequired) {
            try {
                const deductPointsResponse = await fetch(`${API_URL}/deductLoyaltyPoints`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        userId: kundeId,
                        loyaltyPoints: loyaltyPoints - pointsRequired
                    })
                });

                if (deductPointsResponse.ok) {
                    const redeemCodeResponse = await fetch(`${API_URL}/RedeemCode`, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ user_id: kundeId, code_id: offerId })
                    });

                    if (redeemCodeResponse.status === 201) {
                        fetchLoyaltyPoints();
                        fetchVouchers();
                        alert("Angebot erfolgreich eingelöst!");
                    } else {
                        throw new Error("Failed to redeem code after deducting points");
                    }
                } else {
                    throw new Error("Failed to deduct loyalty points");
                }
            } catch (error) {
                console.error("Fehler beim Einlösen des Angebots:", error);
                setError("Fehler beim Einlösen des Angebots");
            }
        } else {
            alert("Nicht genügend Punkte, um dieses Angebot einzulösen.");
        }
    };

    const fetchLoyaltyPoints = () => {
        fetch(API_URL + `/loyaltyPoints/${kundeId}`)
            .then((response) => response.json())
            .then((data) => setLoyaltyPoints(data.loyaltyPoints || 0))
            .catch((error) => {
                console.error('Fehler beim Abrufen der Punkte:', error);
                setError('Fehler beim Abrufen der Punkte');
            });
    };

    const fetchVouchers = () => {
        fetch(API_URL + `/getUserCodes/${kundeId}`)
            .then((response) => response.json())
            .then((data) => setVouchers(data))
            .catch((error) => {
                console.error('Fehler beim Abrufen der Gutscheine:', error);
                setError('Fehler beim Abrufen der Gutscheine');
            });
    };

    const handleBookTickets = () => {
        navigate('/account/stonierungen');
    };

    const handleCancelTicket = (ticketId) => {
        setTickets(tickets.filter(ticket => ticket.id !== ticketId));
    };

    // Update user data
    const handleChangeData = () => {
        const newUserData = { firstName: userData.firstName, lastName: userData.lastName, email: userData.email };
        fetch(`${API_URL}/user/${kundeId}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(newUserData)
        })
            .then((response) => {
                if (response.ok) {
                    alert("Benutzerdaten erfolgreich aktualisiert");
                } else if (response.status === 409) {
                    alert("Die neue E-Mail-Adresse wird bereits verwendet.");
                } else {
                    throw new Error("Failed to update user data");
                }
            })
            .catch((error) => {
                console.error("Fehler beim Aktualisieren der Benutzerdaten:", error);
                setError("Fehler beim Aktualisieren der Benutzerdaten");
            });
    };

    // Change user password
    const handleChangePassword = () => {
        if (!password || !newPassword) {
            alert("Bitte geben Sie sowohl das aktuelle als auch das neue Passwort ein.");
            return;
        }

        fetch(`${API_URL}/changePassword/${kundeId}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({currentPassword: password, newPassword })
        })
            .then((response) => {
                if (response.ok) {
                    alert("Passwort erfolgreich geändert");
                    setPassword('');
                    setNewPassword('');
                } else {
                    alert("Fehler beim Ändern des Passworts. Bitte überprüfen Sie Ihre Eingaben.");
                }
            })
            .catch((error) => {
                console.error("Fehler beim Ändern des Passworts:", error);
                setError("Fehler beim Ändern des Passworts");
            });
    };

    // Function to handle feedback submission for account deletion
    const submitAccountDeletionFeedback = async (reason) => {
        try {
            const response = await fetch(`${API_URL}/addAccountDeletionReason`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ reason })
            });
            if (!response.ok) {
                throw new Error("Fehler beim Senden des Feedbacks zur Kontolöschung");
            }
        } catch (error) {
            console.error("Fehler beim Senden des Feedbacks:", error);
            throw error; // Re-throw the error to handle in the main function
        }
    };

    const handleDeleteAccount = () => {
        // Create a confirmation dialog with the option to cancel or proceed
        if (window.confirm("Möchten Sie Ihr Konto wirklich löschen?")) {
            // Prompt the user for feedback before deleting the account
            const feedback = window.prompt("Bitte geben Sie einen Grund für die Kontolöschung an:");

            if (feedback) {
                // Proceed with account deletion if feedback is provided
                fetch(`${API_URL}/user/${kundeId}`, { method: "DELETE" })
                    .then((response) => {
                        if (response.ok) {
                            // Submit the account deletion feedback
                            return submitAccountDeletionFeedback(feedback)
                                .then(() => {
                                    alert("Konto erfolgreich gelöscht und Feedback übermittelt");
                                    logout();
                                    navigate('/'); // Redirect to homepage
                                });
                        } else {
                            throw new Error("Fehler beim Löschen des Kontos");
                        }
                    })
                    .catch((error) => {
                        console.error("Fehler beim Löschen des Kontos:", error);
                        setError("Fehler beim Löschen des Kontos oder beim Senden des Feedbacks");
                    });
            } else {
                alert("Kontolöschung abgebrochen. Sie müssen einen Grund angeben, um fortzufahren.");
            }
        } else {
            // User chose to cancel the deletion process
            alert("Kontolöschung abgebrochen.");
        }
    };

    return (
        <>
            <MenuBar />
            <div className="container">
                <h1>Konto verwalten</h1>

                <div className="tickets-section">
                    <h2>Meine Tickets:</h2>
                    <button onClick={handleBookTickets}>Tickets stornieren</button>
                    {tickets.map((ticket) => (
                        <div key={ticket.id}>
                            <p>Ticket {ticket.id} ({ticket.bookingInfo})</p>
                            <button onClick={() => handleCancelTicket(ticket.id)}>Ticket stornieren</button>
                        </div>
                    ))}
                </div>

                <div className="loyalty-points-section">
                    <h2>Ihre Loyalitätspunkte: {loyaltyPoints}</h2>
                    <div className="offers">
                        {offers.map((offer) => (
                            <div key={offer.code_id} className="offer">
                                <h3>Angebot: {offer.code}</h3>
                                <p>Rabatt: {offer.discountPercentage}%</p>
                                <p>Kosten: {offer.pointsRequired} Punkte</p>
                                <button
                                    disabled={loyaltyPoints < offer.pointsRequired}
                                    onClick={() => handleRedeemOffer(offer.code_id, offer.pointsRequired)}
                                    className={loyaltyPoints < offer.pointsRequired ? 'disabled' : ''}
                                >
                                    Einlösen
                                </button>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="vouchers-section">
                    <h2>Meine Gutscheine:</h2>
                    {vouchers.map((voucher, index) => {
                        const matchingOffer = offers.find(offer => offer.code_id === voucher.code_id);
                        return (
                            <p key={index}>
                                {matchingOffer ? matchingOffer.code : 'Code nicht gefunden'} ({matchingOffer ? matchingOffer.discountPercentage : '0'}% Rabatt)
                            </p>
                        );
                    })}
                </div>

                <div className="user-data-section">
                    <h2>Meine Daten:</h2>
                    <p>Vorname: <input type="text" value={userData.firstName} onChange={(e) => setUserData({ ...userData, firstName: e.target.value })} /></p>
                    <p>Nachname: <input type="text" value={userData.lastName} onChange={(e) => setUserData({ ...userData, lastName: e.target.value })} /></p>
                    <p>Email: <input type="email" value={userData.email} onChange={(e) => setUserData({ ...userData, email: e.target.value })} /></p>
                    <button onClick={handleChangeData}>Daten ändern</button>
                    <button onClick={handleDeleteAccount} className="delete-account-btn">Konto löschen</button>
                </div>

                <div className="password-section">
                    <h2>Passwort ändern:</h2>
                    <p>Aktuelles Passwort: <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} /></p>
                    <p>Neues Passwort: <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} /></p>
                    <button onClick={handleChangePassword}>Passwort ändern</button>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ManageAccount;