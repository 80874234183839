import React, { useState } from 'react';
import '../../css/worker/CreateDiscountCodes.css'; // Custom CSS file
import Footer from '../../components/Footer';
import { API_URL } from "../../config";
import MenuBarWorker from '../../components/MenuBarWorker';

const CreateDiscountCodes = () => {
    const [code, setCode] = useState('');
    const [discountPercentage, setDiscountPercentage] = useState('');
    const [expirationDate, setExpirationDate] = useState('');
    const [requiresPoints, setRequiresPoints] = useState(false);
    const [pointsRequired, setPointsRequired] = useState(0);
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            code,
            discountPercentage: parseFloat(discountPercentage),
            expirationDate,
            pointsRequired: requiresPoints ? parseInt(pointsRequired) : 0,
            isGeneral: !requiresPoints
        };

        try {
            const response = await fetch(`${API_URL}/Codes`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            if (response.status === 201) {
                setMessage({ text: 'Discount code created successfully!', type: 'success' });
                setCode('');
                setDiscountPercentage('');
                setExpirationDate('');
                setRequiresPoints(false);
                setPointsRequired(0);
            } else {
                setMessage({ text: 'Failed to create discount code.', type: 'error' });
            }
        } catch (error) {
            console.error("Error creating discount code:", error);
            setMessage({ text: 'Failed to create discount code.', type: 'error' });
        }
    };

    return (
        <>
            <MenuBarWorker/>
            <div className="create-discount-code-container">
                <h1>Rabattcodes Erstellen</h1>

                <form onSubmit={handleSubmit} className="discount-code-form">
                <button type="submit"> Erstellen</button>
                    <div className="form-group">
                        <label>Rabattcode:</label>
                        <input
                            type="text"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label>Betrag:</label>
                        <input
                            type="number"
                            value={discountPercentage}
                            onChange={(e) => setDiscountPercentage(e.target.value)}
                            required
                            min="0"
                            max="100"
                            step="0.01"
                        />
                    </div>

                    <div className="form-group">
                        <label>Gueltig bis:</label>
                        <input
                            type="date"
                            value={expirationDate}
                            onChange={(e) => setExpirationDate(e.target.value)}
                            required
                        />
                    </div>

                    <div className="checkbox-group form-group">
                        <input
                            type="checkbox"
                            checked={requiresPoints}
                            onChange={(e) => setRequiresPoints(e.target.checked)}
                        />
                        <label>Loyalitätspunkte einfordern</label>
                    </div>

                    {requiresPoints && (
                        <div className="form-group">
                            <label></label>
                            <input
                                type="number"
                                value={pointsRequired}
                                onChange={(e) => setPointsRequired(e.target.value)}
                                required
                                min="1"
                            />
                        </div>
                    )}


                </form>
                {message && (
                    <p className={`message ${message.type}`}>{message.text}</p>
                )}
            </div>

        </>
    );
};

export default CreateDiscountCodes;