import React, { useState, useEffect } from 'react';
import MenuBar from '../../components/MenuBar'; // Menüleiste importieren
import Footer from '../../components/Footer'; // Footer importieren
import '../../css/worker/BookingOverview.css'; // Optionales CSS für die Tabelle
import MenuBarWorker from '../../components/MenuBarWorker';
import { API_URL } from '../../config';


export default function BookingOverview() {
    // State für die Buchungen
    const [buchungen, setBuchungen] = useState([]);
    const [buchungsdetails, setBuchungsdetails] = useState([]);
    const [loading, setLoading] = useState(true);

    // Lade alle Buchungen aus der Datenbank
    useEffect(() => {
        fetch(API_URL + '/Buchungen') // API-Aufruf an den Backend-Endpunkt
            .then((response) => response.json())
            .then((data) => {
                setBuchungen(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Fehler beim Abrufen der Buchungen:', error);
                setLoading(false);
            });
    }, []);

    // Lade alle Buchungsdetails aus der Datenbank
    useEffect(() => {
        fetch(API_URL + '/Buchungsdetails') // API-Aufruf an den neuen Backend-Endpunkt
            .then((response) => response.json())
            .then((data) => {
                setBuchungsdetails(data);
            })
            .catch((error) => {
                console.error('Fehler beim Abrufen der Buchungsdetails:', error);
            });
    }, []);

    if (loading) {
        return (
            <div className="booking-overview">
                <div className="container">
                    <MenuBar />
                </div>
                <div className="loading">
                    <p>Lädt Buchungen...</p>
                </div>
                <Footer />
            </div>
        );
    }

    return (
        <>
            <MenuBarWorker />

            <div className="worker-container">
                {/* Hauptinhalt */}
                <div className="overview-container">
                    <h1>Alle Buchungen</h1>
                    <table className="booking-table">
                        <thead>
                            <tr>
                                <th>Buchungs-ID</th>
                                <th>Kunden-ID</th>
                                <th>Vorstellungs-ID</th>
                                <th>Buchungsdatum</th>
                                <th>Gesamtpreis (€)</th>
                                <th>Zahlungsmethode</th>
                            </tr>
                        </thead>
                        <tbody>
                            {buchungen.length > 0 ? (
                                buchungen.map((buchung) => (
                                    <tr key={buchung.buchung_id}>
                                        <td>{buchung.buchung_id}</td>
                                        <td>{buchung.kunde_id}</td>
                                        <td>{buchung.vorstellung_id}</td>
                                        <td>{new Date(buchung.buchungsdatum).toLocaleDateString('de-DE')}</td>
                                        <td>{buchung.gesamtpreis.toFixed(2)}</td>
                                        <td>{buchung.zahlungsmethode}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6">Keine Buchungen gefunden</td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                    <h2>Buchungsdetails</h2>
                    <table className="booking-details-table">
                        <thead>
                            <tr>
                                <th>Detail-ID</th>
                                <th>Buchungs-ID</th>
                                <th>Sitz-ID</th>
                                <th>Rabatt</th>
                                <th>Preis (€)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {buchungsdetails.length > 0 ? (
                                buchungsdetails.map((detail) => (
                                    <tr key={detail.detail_id}>
                                        <td>{detail.detail_id}</td>
                                        <td>{detail.buchung_id}</td>
                                        <td>{detail.sitz_id}</td>
                                        <td>{detail.rabatt}</td>
                                        <td>{detail.preis.toFixed(2)}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5">Keine Buchungsdetails gefunden</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            <Footer />
        </>
    );
}