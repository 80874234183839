import React, { useEffect, useState } from 'react';
import MenuBar from '../../components/MenuBar';
import Footer from '../../components/Footer';
import '../../css/customer/Angebote.css';
import { API_URL } from "../../config";
const Angebote = () => {
    const [offersData, setOffersData] = useState([]);

    // Daten für allgemeine Angebote laden
    useEffect(() => {
        const fetchOffers = async () => {
            try {
                const response = await fetch(`${API_URL}/getGeneralCodes`);

                if (response.ok) {
                    const data = await response.json();
                    setOffersData(data);
                } else {
                    console.error('Fehler beim Abrufen der allgemeinen Angebote:', response.status);
                }
            } catch (error) {
                console.error('Fehler beim Abrufen der allgemeinen Angebote:', error);
            }
        };

        fetchOffers();
    }, []);

    return (
        <>
            <MenuBar />
            <div className="main-container">
                <h1>Aktuelle Angebote</h1>
                <div className="offer-container">
                    {offersData.map(offer => (
                        <section key={offer.code_id} className="offer-section">
                            <div className="offer-image" style={{ backgroundImage: `url(${offer.image})` }}></div>
                            <div className="offer-text">
                                <h2>{offer.title}</h2>
                                <p>{offer.description}</p>
                                <p>{offer.discountPercentage}% Rabatt</p>
                                <p>Gültig bis: {offer.expirationDate}</p>
                            </div>
                        </section>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Angebote;