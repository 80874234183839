import React, { useState, useEffect } from 'react';
import MenuBarReporting from '../../components/MenuBarReporting';
import '../../css/reporting/StornoGrund.css';
import { API_URL } from '../../config';

function Stornogrund() {
  const [stornos, setStornos] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // State für die Suchleiste

  // Daten beim Laden der Komponente abrufen
  useEffect(() => {
    fetch(API_URL + '/Storno')
      .then(response => response.json())
      .then(data => setStornos(data))
      .catch(error => console.error("Fehler beim Laden der Daten:", error));
  }, []);

  // Filtert die Stornos nach Stornierungsnummer
  const filteredStornos = stornos.filter(storno =>
    storno.id?.toString().includes(searchTerm) // Filterlogik
  );

  return (
    <>
      <MenuBarReporting />
      <div className="worker-container">
        {/* Storno Section */}
        <section className="storno">
          <h1>Stornierungen</h1>
        </section>

        <section>
          {/* Container für die Suchleiste */}
          <div className="search-input-container">
            <input
              type="text"
              placeholder="Nach Stornierungsnummer suchen..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
          </div>
        </section>

        <section>
          {/* Storno Boxen */}
          <div className="storno-container">
            {filteredStornos.map(storno => (
              <div key={storno.id} className="storno-box">
                <p><strong>Stornierungsnummer: {storno.id}</strong></p>
                <p><strong>Kunden ID: {storno.userId}</strong></p>
                <p>Stornogrund: {storno.reason}</p>
              </div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
}

export default Stornogrund;
