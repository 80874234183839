import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const ProtectedRoute = ({ element, allowedRoles }) => {
    const { isAuthenticated, accountType } = useContext(AuthContext);

    // Falls der Authentifizierungszustand noch nicht initialisiert ist, zeige nichts oder einen Ladeindikator
    if (isAuthenticated === null) {
        return null; // Optional: <div>Loading...</div> für einen Ladeindikator
    }

    // Weiterleiten zur Login-Seite, wenn nicht authentifiziert
    if (!isAuthenticated) {
        return <Navigate to="/login" replace />;
    }

    // Weiterleiten zur Startseite, wenn keine passende Rolle vorhanden ist
    if (!accountType || !allowedRoles.includes(accountType)) {
        return <Navigate to="/" replace />;
    }

    // Rendere die Komponente, wenn authentifiziert und die richtige Rolle vorhanden ist
    return element;
};

export default ProtectedRoute;