import React, { useState, useEffect } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend, PointElement } from 'chart.js';
import MenuBarReporting from '../../components/MenuBarReporting';
import '../../css/reporting/Umsatzgraph.css';

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend);

function Umsatzgraph() {
  // Umsatzdaten für die letzten Monate (Beispiel)
  const [monthlyData, setMonthlyData] = useState({
    labels: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
    datasets: [
      {
        label: 'Umsatz in €',
        data: [12000, 15000, 10000, 17000, 20000, 19000, 22000, 21000, 18000, 23000, 24000, 26000], // Beispielwerte
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderWidth: 2,
      },
    ],
  });

  // Umsatzdaten für die letzten Jahre (Beispiel)
  const [yearlyData, setYearlyData] = useState({
    labels: ['2020', '2021', '2022', '2023'],
    datasets: [
      {
        label: 'Jahresumsatz in €',
        data: [150000, 180000, 220000, 240000], // Beispielwerte
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  });

  // Optionen für das Monatsdiagramm
  const monthlyOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Umsatz in den letzten Monaten',
      },
    },
  };

  // Optionen für das Jahresdiagramm
  const yearlyOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Umsatz in den letzten Jahren',
      },
    },
  };

  return (
    <>
      <MenuBarReporting />
      <div className="worker-container">
        <div className="Umsatzgraph">

          <section className="umsatz">
            <h1>Umsätze</h1>
          </section>
          <section>
            {/* Liniendiagramm für monatliche Umsätze */}
            <div className="chart-container">
              <Line data={monthlyData} options={monthlyOptions} />
            </div>

            {/* Balkendiagramm für jährliche Umsätze */}
            <div className="chart-container">
              <Bar data={yearlyData} options={yearlyOptions} />
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Umsatzgraph;
