import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../../css/account/formular.css';
import { API_URL } from '../../config';
import MenuBarReporting from '../../components/MenuBarReporting';

const AddTestdata = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [gender, setGender] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});

    // Funktion zur Validierung des Namens
    const validateName = (name) => {
        const namePattern = /^[A-Za-z]+$/; // Nur Buchstaben, keine Leerzeichen
        return namePattern.test(name);
    };

    // Funktion zur Validierung des Geburtsdatums
    const validateBirthDate = (date) => {
        const today = new Date();
        const selectedDate = new Date(date);
        return selectedDate < today; // Geburtsdatum muss in der Vergangenheit liegen
    };

    // Funktion zur Behandlung der Registrierung
    const handleRegister = (e) => {
        e.preventDefault();

        // Fehlerobjekt zurücksetzen
        let newErrors = {};

        // Vorname validieren
        if (!validateName(firstName)) {
            newErrors.firstName = 'Vorname darf nur Buchstaben enthalten!';
        }

        // Nachname validieren
        if (!validateName(lastName)) {
            newErrors.lastName = 'Nachname darf nur Buchstaben enthalten!';
        }

        // Geburtsdatum validieren
        if (!validateBirthDate(birthDate)) {
            newErrors.birthDate = 'Geburtsdatum muss in der Vergangenheit liegen!';
        }

        // Falls Fehler existieren, diese setzen und Verarbeitung stoppen
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            // Wenn keine Fehler, Daten registrieren
            const registerData = {
                firstName: firstName,
                lastName: lastName,
                email: email,
                password: password,
                birthDate: birthDate,
                gender: gender,
                accountType: "customer",
            };
            registerUser(registerData);
        }
    };

    // Funktion zum Registrieren eines einzelnen Benutzers
    const registerUser = (data) => {
        fetch(API_URL + '/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                if (response.ok) {
                    console.log('Registration erfolgreich gespeichert!');
                } else {
                    console.error('Fehler bei der Registrierung:', response);
                }
            })
            .catch((error) => console.error('Fehler:', error));
    };

    // Funktion zum Anlegen einer Stornierung 
    const submitFeedback = async (reason, userId) => {
        try {
            const response = await fetch(API_URL + '/addCancellationReason/' + userId, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ reason })
            });
            if (!response.ok) {
                throw new Error('Fehler beim Senden des Feedbacks');
            }
        } catch (error) {
            console.error('Fehler beim Feedback senden:', error);
            throw error; // Re-throw the error to handle it in the main function
        }
    };

    const submitReview = async (rating, review) => {

        const reviewData = {
            rating: rating,
            review: review
        };

        // Send the review data to the server
        fetch(`${API_URL}/addReview`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(reviewData)
        })
            .catch((error) => {
                console.error('Fehler beim Speichern der Bewertung:', error);
            });
    };



    // Funktion zur Generierung eines zufälligen Geburtsdatums basierend auf der Altersverteilung
    const generateRandomBirthDate = () => {

        // Altersverteilung
        const ageDistribution = [
            { min: 18, max: 24, percentage: 30 },   // 30% Wahrscheinlichkeit
            { min: 25, max: 34, percentage: 25 },   // 25% Wahrscheinlichkeit
            { min: 35, max: 44, percentage: 20 },   // 20% Wahrscheinlichkeit
            { min: 45, max: 54, percentage: 15 },   // 15% Wahrscheinlichkeit
            { min: 55, max: 64, percentage: 7 },    //  7% Wahrscheinlichkeit
            { min: 65, max: 100, percentage: 3 }    //  3% Wahrscheinlichkeit
        ];

        // Kumulierte Prozentsätze berechnen
        const cumulativeDistribution = [];
        let cumulativePercentage = 0;
        for (let i = 0; i < ageDistribution.length; i++) {
            cumulativePercentage += ageDistribution[i].percentage;
            cumulativeDistribution.push({
                min: ageDistribution[i].min,
                max: ageDistribution[i].max,
                cumulativePercentage: cumulativePercentage,
            });
        }

        // Zufällige Zahl zwischen 0 und 100 generieren
        const rand = Math.random() * 100;

        // Altersgruppe basierend auf der Zufallszahl auswählen
        let selectedRange;
        for (let i = 0; i < cumulativeDistribution.length; i++) {
            if (rand <= cumulativeDistribution[i].cumulativePercentage) {
                selectedRange = cumulativeDistribution[i];
                break;
            }
        }

        // Zufälliges Alter innerhalb der ausgewählten Gruppe generieren
        const age = selectedRange.min + Math.floor(Math.random() * (selectedRange.max - selectedRange.min + 1));

        // Geburtsdatum basierend auf dem Alter berechnen
        const today = new Date();
        const birthYear = today.getFullYear() - age;
        const birthMonth = Math.floor(Math.random() * 12); // Monate von 0-11
        const birthDay = Math.floor(Math.random() * 28) + 1; // Tage von 1-28

        const birthDate = new Date(birthYear, birthMonth, birthDay);

        return birthDate.toISOString().split('T')[0]; // Format: YYYY-MM-DD
    };

    // Funktion zum Generieren zufälliger Benutzerdaten mit realistischen Geschlechterverteilungen
    const generateRandomData = () => {
        const maleFirstNames = ['Max', 'Peter', 'John', 'Paul', 'Robert', 'Michael', 'Thomas', 'Alexander', 'Daniel', 'David', 'Stefan', 'Martin', 'Lukas', 'Andreas', 'Sebastian', 'Florian', 'Jan', 'Simon', 'Christian', 'Markus', 'Kevin', 'Philipp', 'Tim', 'Jonas', 'Leon', 'Niklas', 'Felix', 'Benjamin', 'Matthias', 'Dominik', 'Julian', 'Marcel', 'Erik', 'Sven', 'Tobias', 'Oliver', 'Patrick', 'Christopher', 'Luca', 'Nico', 'Dennis', 'Fabian', 'Jens', 'Kai', 'Marc', 'Richard', 'Frank', 'Jörg', 'Uwe', 'Wolfgang', 'Ralf', 'Heiko', 'Holger', 'Bernd', 'Björn', 'Klaus', 'Jürgen', 'Manuel', 'Helmut', 'Günter', 'Rainer', 'Thorsten', 'Udo', 'Volker', 'André', 'Axel', 'Carsten', 'Dietmar', 'Ewald', 'Friedrich', 'Gerd', 'Harald', 'Ingo', 'Kurt', 'Lothar', 'Norbert', 'Otto', 'Roland', 'Steffen', 'Torsten', 'Ulrich', 'Werner', 'Yannik', 'Arthur', 'Benedikt', 'Cornelius', 'Dominic', 'Elias', 'Ferdinand', 'Gabriel', 'Henrik', 'Ivan', 'Jacob', 'Karl', 'Leonard', 'Matteo', 'Nathan', 'Oscar', 'Patrick', 'Quentin', 'Rafael', 'Samuel', 'Theodor', 'Ulrich', 'Victor', 'Willi', 'Xaver', 'Yannick', 'Zacharias'];
        const femaleFirstNames = ['Anna', 'Laura', 'Maria', 'Linda', 'Sarah', 'Julia', 'Emma', 'Sophie', 'Lena', 'Leonie', 'Emily', 'Hannah', 'Mia', 'Nina', 'Johanna', 'Lisa', 'Sophia', 'Amelie', 'Clara', 'Paula', 'Luisa', 'Franziska', 'Katharina', 'Isabella', 'Charlotte', 'Mila', 'Melina', 'Theresa', 'Vanessa', 'Eva', 'Alexandra', 'Jasmin', 'Elena', 'Lilly', 'Lara', 'Nele', 'Klara', 'Michelle', 'Sandra', 'Pia', 'Carolin', 'Victoria', 'Anja', 'Sabrina', 'Christina', 'Annika', 'Marlene', 'Susanne', 'Magdalena', 'Verena', 'Angelina', 'Bianca', 'Carina', 'Daniela', 'Elisa', 'Helena', 'Kim', 'Maja', 'Nadine', 'Rebecca', 'Stephanie', 'Tamara', 'Antonia', 'Anastasia', 'Carla', 'Eleni', 'Greta', 'Iris', 'Janina', 'Kathrin', 'Lilian', 'Miriam', 'Olivia', 'Petra', 'Rita', 'Sabine', 'Tanja', 'Ulrike', 'Yvonne', 'Zoe', 'Alicia', 'Beatrice', 'Corinna', 'Diana', 'Elisabeth', 'Fiona', 'Gisela', 'Heike', 'Ines', 'Jessica', 'Kerstin', 'Larissa', 'Monika', 'Nicole', 'Patricia', 'Renate', 'Silke', 'Tina', 'Ute', 'Viktoria', 'Wilma', 'Angela', 'Berit', 'Carmen', 'Doreen'];
        const lastNames = ['Müller', 'Schmidt', 'Schneider', 'Fischer', 'Weber', 'Meyer', 'Wagner', 'Becker', 'Schulz', 'Hoffmann', 'Koch', 'Bauer', 'Richter', 'Klein', 'Wolf', 'Schäfer', 'Neumann', 'Schröder', 'Schwarz', 'Zimmermann', 'Braun', 'Krüger', 'Hartmann', 'Hofmann', 'Lange', 'Schmitt', 'Werner', 'Schmitz', 'Krause', 'Meier', 'Lehmann', 'Schmid', 'Schulze', 'Maier', 'Köhler', 'Herrmann', 'Walter', 'König', 'Mayer', 'Huber', 'Kaiser', 'Fuchs', 'Peters', 'Lang', 'Scholz', 'Möller', 'Weiß', 'Jung', 'Hahn', 'Schubert', 'Vogel', 'Friedrich', 'Keller', 'Günther', 'Frank', 'Berger', 'Winkler', 'Roth', 'Beck', 'Lorenz', 'Baumann', 'Franke', 'Albrecht', 'Schuster', 'Simon', 'Ludwig', 'Böhm', 'Winter', 'Kraus', 'Martin', 'Schumacher', 'Krämer', 'Vogt', 'Jäger', 'Stein', 'Otto', 'Groß', 'Sommer', 'Haas', 'Graf', 'Seidel', 'Heinrich', 'Brandt', 'Schreiber', 'Dietrich', 'Ziegler', 'Kuhn', 'Kühn', 'Pohl', 'Engel', 'Horn', 'Busch', 'Bergmann', 'Thomas', 'Voigt', 'Sauer', 'Arnold', 'Wolff', 'Pfeiffer', 'Lehner'];

        // Geschlecht mit realistischer Verteilung generieren
        const randomGender = () => {
            const rand = Math.random();
            if (rand < 0.495) {
                return 'male';
            } else if (rand < 0.99) {
                return 'female';
            } else {
                return 'other';
            }
        };

        const gender = randomGender();
        let firstName;

        if (gender === 'male') {
            firstName = maleFirstNames[Math.floor(Math.random() * maleFirstNames.length)];
        } else if (gender === 'female') {
            firstName = femaleFirstNames[Math.floor(Math.random() * femaleFirstNames.length)];
        } else {
            const allFirstNames = maleFirstNames.concat(femaleFirstNames);
            firstName = allFirstNames[Math.floor(Math.random() * allFirstNames.length)];
        }

        const lastName = lastNames[Math.floor(Math.random() * lastNames.length)];

        const email = `${firstName.toLowerCase()}.${lastName.toLowerCase()}${Math.floor(Math.random() * 1000)}@example.com`;

        const birthDate = generateRandomBirthDate();

        const password = Math.random().toString(36).slice(-8); // Zufälliges 8-stelliges Passwort
        // https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript

        return {
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
            birthDate: birthDate,
            gender: gender,
            accountType: "customer",
        };
    };

    // Funktion zum Generieren zufälliger Stornierungen
    const generateStorno = () => {

        const randomUserId = Math.floor(Math.random() * 10001);

        // Array mit Storno-Vorlagen
        const stornos = [
            "Ich bin leider krank geworden und kann nicht ins Kino gehen.",
            "Etwas Dringendes ist dazwischengekommen, sodass ich meinen Kinobesuch absagen muss.",
            "Meine Freunde haben abgesagt, und alleine möchte ich nicht ins Kino gehen.",
            "Der Weg ins Kino ist wegen eines Verkehrschaos' einfach nicht machbar, deshalb storniere ich die Karten.",
            "Nachdem ich einige schlechte Bewertungen zum Film gelesen habe, möchte ich ihn doch nicht sehen.",
            "Ein familiärer Notfall ist aufgetreten, und ich muss den Kinobesuch leider absagen.",
            "Meine Arbeitszeiten haben sich spontan geändert, und ich kann es daher nicht zum Kino schaffen.",
            "Ich habe die Lust auf den Film irgendwie verloren und möchte ihn jetzt doch nicht anschauen.",
            "Da ich von außerhalb komme und die Anreise nicht mehr möglich ist, muss ich die Tickets stornieren.",
            "Die Vorstellung ist mir jetzt doch zu spät, das passt leider nicht mehr in meinen Zeitplan.",
            "Ich habe versehentlich doppelt gebucht und muss die doppelten Tickets wieder zurückgeben.",
            "Ich habe leider die falschen Tickets gebucht und möchte mir deshalb andere kaufen."
        ];

        // Funktion zur zufälligen Auswahl eines Storno-Grunds
        function getZufälligerStornoGrund() {
            const index = Math.floor(Math.random() * stornos.length);
            return stornos[index];
        }

        const storno = {
            userId: randomUserId,
            reason: getZufälligerStornoGrund()
        }

        // Ausgabe im Terminal
        console.log("Stornierung:");
        console.log("Benutzer-ID:", storno.userId);
        console.log("Grund:", storno.reason);

        return storno;
    }

    // Funktion zum Generieren zufälliger Stornierungen
    const generateFeedback = () => {

        const feedbackOptions = [
            { stars: 5, text: "Die Buchung war super einfach und schnell! Alles klar verständlich und ohne Probleme – so sollte es immer sein." },
            { stars: 4, text: "Ich konnte die Tickets problemlos buchen, aber ich hätte mir gewünscht, dass mehr Zahlungsmethoden verfügbar sind." },
            { stars: 3, text: "Die Buchung hat zwar funktioniert, aber es gab ein paar technische Schwierigkeiten, die den Prozess verlängert haben." },
            { stars: 5, text: "Alles lief perfekt! Von der Auswahl bis zur Zahlung war die Buchung kinderleicht. Wirklich top!" },
            { stars: 4, text: "Die Buchung war einfach und schnell, nur die Bestätigungsmail kam etwas spät an." },
            { stars: 2, text: "Es hat lange gedauert, bis die Buchung durchging. Irgendwie hat die Seite mehrfach neu geladen, was genervt hat." },
            { stars: 3, text: "Die Buchung ging okay, aber das Interface könnte ein bisschen übersichtlicher sein, besonders bei der Sitzplatzauswahl." },
            { stars: 1, text: "Leider hat die Seite während der Buchung ständig gehangen, sodass ich mehrfach neu anfangen musste." },
            { stars: 5, text: "Perfekte Buchungserfahrung! Alles hat reibungslos geklappt, und ich habe sofort die Bestätigung erhalten." },
            { stars: 4, text: "Die Buchung war unkompliziert und schnell. Ich würde mir nur wünschen, dass man mehr Details zum Film angezeigt bekommt." },
            { stars: 3, text: "Die Buchung hat funktioniert, aber die Seite wirkte ein bisschen langsam. Ansonsten okay." },
            { stars: 5, text: "Ich bin begeistert! Alles lief ohne einen einzigen Fehler ab. Gerne wieder!" },
            { stars: 4, text: "Sehr praktisch! Die Buchung war schnell und einfach, allerdings könnte die Sitzplatzauswahl noch übersichtlicher sein." },
            { stars: 5, text: "Einwandfreier Buchungsprozess, alles lief glatt und ohne Verzögerungen." },
            { stars: 2, text: "Ich hatte Schwierigkeiten mit der Sitzplatzwahl, die Auswahl war verwirrend." },
            { stars: 0, text: "Katastrophe! Die Seite hat nicht geladen, und ich konnte kein Ticket buchen." },
            { stars: 1, text: "Die Buchung hat sich mehrfach aufgehängt und war sehr frustrierend." },
            { stars: 3, text: "Es ging, aber ich hatte mir mehr Optionen zur Sitzplatzwahl erhofft." },
            { stars: 2, text: "Nach mehrmaligen Versuchen hat es endlich funktioniert, aber es war nervig." },
            { stars: 4, text: "Angenehme Erfahrung, nur die Ladezeiten könnten kürzer sein." },
            { stars: 5, text: "Alles perfekt! Sehr intuitive Bedienung und schnelle Bestätigung." },
            { stars: 0, text: "Unmöglich zu buchen, die Seite stürzt bei jedem Versuch ab." },
            { stars: 3, text: "Es hat funktioniert, aber der Prozess war langsamer als erwartet." },
            { stars: 4, text: "Gute Benutzerführung, aber die Bestätigungsmail dauerte länger als gedacht." },
            { stars: 1, text: "Die Seite hat mich mehrfach abgemeldet, sodass ich wieder von vorne anfangen musste." },
            { stars: 5, text: "Schnelle und unkomplizierte Buchung. Alles war sofort verständlich." },
            { stars: 3, text: "Durchschnittliche Erfahrung, nichts besonderes, aber auch keine Probleme." },
            { stars: 2, text: "Zu kompliziert und zu viele Schritte für eine einfache Buchung." },
            { stars: 1, text: "Mehrere technische Probleme, bis die Buchung endlich geklappt hat." },
            { stars: 4, text: "Gute Seite, aber die Sitzplatzübersicht könnte besser sein." },
            { stars: 5, text: "Perfekt! Keine einzige Komplikation. So macht das Buchen Spaß." },
            { stars: 3, text: "Die Buchung war okay, aber etwas träge in der Reaktion." },
            { stars: 2, text: "Zu viele Ladezeiten. Der Prozess könnte deutlich flüssiger sein." },
            { stars: 4, text: "Schnell und effizient, aber die Zahlungsmöglichkeiten könnten mehr sein." },
            { stars: 0, text: "Katastrophe, die Buchung ist trotz mehrerer Versuche nicht möglich." },
            { stars: 5, text: "Einfach und klar verständlich, genau wie es sein sollte!" },
            { stars: 1, text: "Extrem langsame Seite und sehr frustrierend zu nutzen." },
            { stars: 3, text: "Buchung okay, aber nicht ganz reibungslos." },
            { stars: 4, text: "Angenehm und einfach, aber die Ladezeiten könnten kürzer sein." },
            { stars: 5, text: "Super! Alles lief reibungslos und schnell ab." },
            { stars: 0, text: "Die Seite funktioniert gar nicht. Schade." },
            { stars: 1, text: "Die Buchung wurde mehrmals abgebrochen, sehr frustrierend." },
            { stars: 2, text: "Viele kleine Bugs, die das Buchen mühsam machten." },
            { stars: 4, text: "Zufrieden, nur die Sitzplatzanzeige könnte intuitiver sein." },
            { stars: 5, text: "Keine Probleme, alles war einfach und schnell." },
            { stars: 3, text: "Funktioniert, aber das Layout könnte besser sein." },
            { stars: 2, text: "Es hat geklappt, aber sehr zäh und langsam." },
            { stars: 1, text: "Die Seite ist ständig abgestürzt, bis es endlich funktionierte." },
            { stars: 4, text: "War okay, aber die Auswahlmöglichkeiten für Sitze sind eingeschränkt." },
            { stars: 5, text: "Sehr zufrieden! So einfach und schnell wie erwartet." },
            { stars: 0, text: "Die Buchung konnte trotz mehrfacher Versuche nicht abgeschlossen werden." },
            { stars: 3, text: "Die Buchung hat geklappt, aber es gab leichte Verzögerungen." },
            { stars: 4, text: "Guter Buchungsprozess, die Navigation könnte aber übersichtlicher sein." },
            { stars: 5, text: "Absolut problemlos! Die Bestätigung kam sofort an." },
            { stars: 2, text: "Die Buchung ging erst nach mehreren Anläufen durch." },
            { stars: 1, text: "Es war extrem frustrierend, die Buchung abzuschließen." },
            { stars: 0, text: "Keine Chance, ein Ticket zu buchen. Die Seite lädt nicht." },
            { stars: 5, text: "Einfach und schnell, wirklich perfekt." },
            { stars: 4, text: "Sehr gut, aber etwas umständliche Sitzplatzauswahl." },
            { stars: 3, text: "Buchung war okay, aber nicht besonders schnell." },
            { stars: 2, text: "Viele kleine Probleme während des Buchungsprozesses." },
            { stars: 1, text: "Langsam und ständig abstürzende Seite, keine gute Erfahrung." },
            { stars: 0, text: "Unmöglich zu buchen. Alles versucht, aber nichts klappt." },
            { stars: 5, text: "Schnell und einfach, gerne wieder!" },
            { stars: 4, text: "Insgesamt zufriedenstellend, auch wenn es etwas gedauert hat." },
            { stars: 3, text: "Akzeptabel, aber Verbesserungsbedarf bei der Ladezeit." },
            { stars: 2, text: "Die Seite lief nicht flüssig, was das Buchen erschwerte." },
            { stars: 1, text: "Technische Probleme während der gesamten Buchung." },
            { stars: 0, text: "Ticketbuchung hat gar nicht funktioniert." },
            { stars: 5, text: "Reibungslose Buchung und sofortige Bestätigung." },
            { stars: 4, text: "In Ordnung, aber die Ladezeiten könnten kürzer sein." },
            { stars: 3, text: "Funktioniert, aber die Navigation ist etwas kompliziert." },
            { stars: 2, text: "Klappt, aber dauert länger als erwartet." },
            { stars: 1, text: "Seite friert ständig ein, sehr mühsam." },
            { stars: 0, text: "Kein Erfolg beim Buchen, immer wieder Fehler." },
            { stars: 5, text: "Exzellent, super einfacher Prozess!" },
            { stars: 4, text: "Gut, aber Bestätigungsmail kam verzögert." },
            { stars: 3, text: "Buchung okay, aber Verbesserungsbedarf." },
            { stars: 2, text: "Schwierig und verwirrend zu bedienen." },
            { stars: 1, text: "Unzufrieden, da die Seite oft abstürzte." },
            { stars: 0, text: "Die Buchung scheiterte jedes Mal, ärgerlich!" },
            { stars: 5, text: "Lief perfekt, gerne wieder!" }
        ];



        // Funktion zur zufälligen Auswahl eines Feedbacks
        function getZufälligesFeedback() {
            const index = Math.floor(Math.random() * feedbackOptions.length);
            return feedbackOptions[index];
        }

        const feedback = {
            rating: getZufälligesFeedback().stars,
            review: getZufälligesFeedback().text
        };

        return feedback;
    }


    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    // Allgemeine Funktion zum Laden von Testdatensätzen
    const loadTestData = async (count) => {
        for (let i = 0; i < count; i++) {
            const testData = generateRandomData();
            await registerUser(testData);
            await delay(50);
        }
        alert(`${count} Testdatensätze wurden geladen.`);
    };

    // Allgemeine Funktion zum Laden von Testdatensätzen
    const loadFeedback = async (count) => {
        for (let i = 0; i < count; i++) {
            const testData = generateFeedback();
            await submitReview(testData.rating, testData.review);
            await delay(50);
        }
        alert(`${count} Testdatensätze wurden geladen.`);
    };

    // Funktion zum Generieren mehrerer Stornierungen
    const loadStornoData = async (count) => {
        for (let i = 0; i < count; i++) {
            const storno = generateStorno();
            await submitFeedback(storno.reason, storno.userId);
            await delay(50);
        }
        alert(`${count} Stornogründe wurden generiert.`);
    };

    return (
        <>
            <MenuBarReporting />
            <div className="worker-container">
                <div className="banner">
                    <h1>Verschiedene Testdaten generieren:</h1>
                </div>
                <div>
                    {/* Bei 1000x Laden ist SQLite überfordert */}
                    <p style={{ marginTop: '20px' }}>Testdatensätze für Benutzer generieren:</p>
                    <button onClick={() => loadTestData(100)}>100</button>
                    <button onClick={() => loadTestData(200)}>200</button>
                    <button onClick={() => loadTestData(500)}>500</button>
                    <p style={{ marginTop: '20px' }}>Testdaten für Stornogründe generieren</p>
                    <button onClick={() => loadStornoData(100)}>100</button>
                    <button onClick={() => loadStornoData(200)}>200</button>
                    <button onClick={() => loadStornoData(500)}>500</button>
                    <p style={{ marginTop: '20px' }}>Testdaten für Feedback generieren</p>
                    <button onClick={() => loadFeedback(100)}>100</button>
                    <button onClick={() => loadFeedback(200)}>200</button>
                    <button onClick={() => loadFeedback(500)}>500</button>
                </div>
            </div>
        </>
    );
};

export default AddTestdata;
