import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API_URL } from "../config";

export default function Footer() {
    const [oeffnungszeitenList, setOeffnungszeitenList] = useState([]);

    // Liste der Wochentage
    const wochentage = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];

    useEffect(() => {
        // Fetch existing opening hours
        fetch(API_URL + '/Oeffnungzeiten')
            .then(response => response.json())
            .then(data => {
                setOeffnungszeitenList(data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    return (
        <nav className="main-footer">
            <ul>
                <li><Link to="/AGB">AGB</Link></li>
                <li><Link to="/Datenschutz">Datenschutz</Link></li>
                <li><Link to="/Impressum">Impressum</Link></li>
                <li><Link to="/Kontakt">Kontakt</Link></li>
                <li><Link to="/FAQ">FAQ</Link></li>
            </ul>

            <div className="oeffnungszeiten">
                <h4>Öffnungszeiten</h4>
                <ul>
                    {wochentage.map((tag, index) => {
                        const oeffnungszeit = oeffnungszeitenList.find(zeit => zeit.wochentag === tag);

                        return (
                            <li key={index}>
                                {tag}: {oeffnungszeit ? `${oeffnungszeit.oeffnungszeit} - ${oeffnungszeit.schlusszeit}` : "Geschlossen"}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </nav>
    );
}