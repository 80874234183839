import React from 'react';
import { Link } from 'react-router-dom';
import MenuBarReporting from '../../components/MenuBarReporting';

function ReportingHome() {
  const largeButtonStyle = {
    padding: '12px 24px',
    fontSize: '16px',
    backgroundColor: '#000000', // Example background color, adjust as needed
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'inline-block',
    textAlign: 'center',
  };

  return (
    <>
      <MenuBarReporting />
      <div className="worker-container">
        <h1>Reporting</h1>
        <nav>
          <ul className="navigation-menu">
            <li>
              <Link to="/Kundendaten">Kundendaten</Link>
            </li>
            <li>
              <Link to="/feedback">Feedback</Link>
            </li>
            <li>
              <Link to="/StornoGrund">Stornierungen</Link>
            </li>
          </ul>
        </nav>
        <div style={{ marginTop: '20px' }}>
          <Link to="/addTestdaten" style={largeButtonStyle}>
            Testdaten
          </Link>
        </div>
      </div>
    </>
  );
}

export default ReportingHome;
