import React, { useState, useEffect } from 'react';
import { API_URL } from '../../config';
import MenuBar from '../../components/MenuBar';
import Footer from '../../components/Footer';


const NutzerVerwalten = () => {
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = () => {
        fetch(`${API_URL}/users`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => setUsers(data))
            .catch((error) => setError(error));
    };

    const deleteUser = (userId) => {
        fetch(`${API_URL}/users/${userId}`, {
            method: 'DELETE',
        })
            .then((response) => {
                if (response.ok) {
                    setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
                } else {
                    throw new Error('Fehler beim Löschen des Nutzers');
                }
            })
            .catch((error) => {
                console.error('Fehler:', error);
                setError(error);
            });
    };

    return (
        <>
            <MenuBar />
            <div className="main-container">
                <div className="nutzerverwalten-container">
                    <h1>Nutzer verwalten</h1>
                    {error && <p className="error">Fehler beim Laden der Nutzer: {error.message}</p>}
                    <ul>
                        {users.map((user) => (
                            <li key={user.id}>
                                {user.firstName} {user.lastName} ({user.email})
                                <button onClick={() => deleteUser(user.id)}>Löschen</button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default NutzerVerwalten;
