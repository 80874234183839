import React, { useEffect, useState } from 'react';
import MenuBarWorker from '../../components/MenuBarWorker';
import '../../css/worker/VeranstaltungenLoeschen.css';
import { API_URL } from '../../config';

const SaeleLoeschen = () => {
    const [saele, setSaele] = useState([]);
    const [selectedSaele, setSelectedSaele] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        // Fetch Säle from Javalin backend
        fetch(API_URL + '/SaalLaden')
            .then(response => response.json())
            .then(data => {
                setSaele(data);
            })
            .catch(error => {
                console.error('Error fetching halls data:', error);
            });
    }, []);

    const handleSelect = (id) => {
        setSelectedSaele(prevSelected =>
            prevSelected.includes(id)
                ? prevSelected.filter(item => item !== id)
                : [...prevSelected, id]
        );
    };

    const selectAll = () => {
        if (selectedSaele.length === saele.length) {
            setSelectedSaele([]);
        } else {
            setSelectedSaele(saele.map(saal => saal.saal_id));
        }
    };

    const handleDelete = () => {
        const deleteRequests = selectedSaele.map(id =>
            fetch(API_URL + `/Saal/${id}`, {
                method: 'DELETE'
            })
        );

        Promise.all(deleteRequests)
            .then(() => {
                setSaele(prevSaele =>
                    prevSaele.filter(saal =>
                        !selectedSaele.includes(saal.saal_id)
                    )
                );
                setSelectedSaele([]);
            })
            .catch(error => {
                console.error('Error deleting halls:', error);
            });
    };

    // Filter halls based on the search term (by hall ID)
    const filteredSaele = saele.filter(saal =>
        saal.saal_id.toString().includes(searchTerm)
    );

    return (
        <>
            <MenuBarWorker />
            <div className='worker-container'>
                <h1>Säle Löschen</h1>
                <div className='search-container'>
                    <input
                        type="text"
                        placeholder="Suche nach Saal-ID..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                <div className='Tasten'>
                    <button onClick={handleDelete}>Löschen</button>
                    <button onClick={selectAll}>Alle Auswählen</button>
                </div>
                <div className='tabellenContainer'>
                    <table>
                        <thead>
                            <tr>
                                <th>Auswählen</th>
                                <th>Saal-ID</th>
                                <th>Reihe</th>
                                <th>Nummer</th>
                                <th>Kategorie</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredSaele.map((saal) => (
                                <tr key={saal.saal_id}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={selectedSaele.includes(saal.saal_id)}
                                            onChange={() => handleSelect(saal.saal_id)}
                                        />
                                    </td>
                                    <td>{saal.saal_id}</td>
                                    <td>{saal.reihe}</td>
                                    <td>{saal.nummer}</td>
                                    <td>{saal.kategorie}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default SaeleLoeschen;
