import React, { useState } from 'react';
import MenuBar from '../../components/MenuBar';
import Footer from '../../components/Footer';
import '../../css/Footer/Kontakt.css';

const Kontakt = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [recipient, setRecipient] = useState('');
    const [type, setType] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState({});

    const handleContactSubmit = (e) => {
        e.preventDefault();

        // Fehlerobjekt zurücksetzen
        let newErrors = {};

        // Beispiel einer Validierung für das Email-Feld
        if (!email.includes('@')) {
            newErrors.email = 'Bitte eine gültige E-Mail-Adresse eingeben!';
        }

        // Wenn Fehler vorliegen, setze sie und stoppe die Verarbeitung
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

    };

    return (
        <>
            <MenuBar />
            <div className="main-container">
                <section aria-labelledby="kontakt-title">
                    <h1 id="kontakt-title">Kontakt</h1>
                </section>

                <form className="contact-form" onSubmit={handleContactSubmit}>
                    <label htmlFor="email">E-Mail-Adresse*</label>
                    <input
                        id="email"
                        type="email"
                        placeholder="E-Mail-Adresse*"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    {errors.email && <p className="error">{errors.email}</p>}

                    <label htmlFor="name">Name*</label>
                    <input
                        id="name"
                        type="text"
                        placeholder="Name*"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />

                    <label htmlFor="phone">Telefon</label>
                    <input
                        id="phone"
                        type="tel"
                        placeholder="Telefon"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />

                    <label htmlFor="cardNumber">Kartennummer</label>
                    <input
                        id="cardNumber"
                        type="text"
                        placeholder="Kartennummer"
                        value={cardNumber}
                        onChange={(e) => setCardNumber(e.target.value)}
                    />

                    <label htmlFor="recipient">Empfänger auswählen*</label>
                    <select
                        id="recipient"
                        value={recipient}
                        onChange={(e) => setRecipient(e.target.value)}
                        required
                    >
                        <option value="">Empfänger auswählen*</option>
                        <option value="support">Support</option>
                        <option value="tickets">Tickets</option>
                        <option value="allgemein">Allgemeine Anfragen</option>
                    </select>

                    <fieldset>
                        <label >Art der Anfrage </label>

                        <div className="radio-group">
                            <label>
                                <input
                                    type="radio"
                                    name="type"
                                    value="lob"
                                    onChange={(e) => setType(e.target.value)}
                                /> Lob
                            </label>

                            <label>
                                <input
                                    type="radio"
                                    name="type"
                                    value="kritik"
                                    onChange={(e) => setType(e.target.value)}
                                /> Kritik
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="type"
                                    value="anfrage"
                                    onChange={(e) => setType(e.target.value)}
                                /> Anfrage
                            </label>
                        </div>
                    </fieldset>
                    <br />
                    <label htmlFor="subject">Betreff*</label>
                    <input
                        id="subject"
                        type="text"
                        placeholder="Betreff*"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        required
                    />

                    <label htmlFor="message">Dein Anliegen*</label>
                    <textarea
                        id="message"
                        placeholder="Dein Anliegen*"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        rows="4"
                        required
                    />

                    <button type="submit">Absenden</button>
                </form>


                <div class="info">
                    MovieHub Entertainment GmbH<br />
                    Hauptstraße 123<br />
                    12345 Beispielstadt
                    <br /><br />
                    <a href="/FAQ">Zu den FAQ</a>
                    
                </div>


            </div>
            <Footer />
        </>
    );
};

export default Kontakt;
